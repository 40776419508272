import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./styles.m.css";
import { Link } from "@quintype/components";

const Menus = ({ items, onMegaMenuToggle, isMegaMenuOpen, itemCount = 9 }) => {
  let menus = items
    .filter(item => item.title && item.path)
    .slice(0, itemCount)
    .map(item => {
      const externalLink = item.isExternalLink ? item.path : null;
      const subMenu = get(item, ["items"], []);
      const color = get(item, ["data", "color"]);
      return (
        <li itemProp="name" role="menuitem" key={item.id} className={`${styles["menu-item"]} ${styles["border-top"]}`}>
          <Link
            itemProp="url"
            aria-label={`${"Read More about"} ${item.title}`}
            className={`${styles["menu-item-link"]} ${styles["menu-item-first-child"]}`}
            href={item.url || item.path}
            externalLink={externalLink}
          >
            <div className={styles["right-arrow"]} />
            {item.title} {color === "#00000F" && <span className={styles["new-menu"]}>New</span>}
          </Link>
          {subMenu.length > 0 && (
            <ul className={styles["sub-menu"]}>
              {subMenu.map(subitem => (
                <li key={subitem.id} className={styles["sub-menu-item"]}>
                  <Link
                    aria-label={`${"Read More about"} ${subitem.title}`}
                    className={styles["sub-menu-link"]}
                    href={`${subitem.path || subitem.url}?pfrom=sub-menu-header`}
                    externalLink={subitem.isExternalLink && subitem.path}
                  >
                    {subitem.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    });
  return (
    <ul
      itemScope
      id="topnav"
      itemType="https://schema.org/SiteNavigationElement"
      role="menu"
      className={`${styles.menus}`}
    >
      {menus}
    </ul>
  );
};

Menus.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  onMegaMenuToggle: PropTypes.func,
  isMegaMenuOpen: PropTypes.bool,
  itemCount: PropTypes.number
};

const SecondaryMenubar = ({ secondaryMenu, isMegaMenuOpen, onMegaMenuToggle, onSearchToggle, searchButtonRef }) => {
  const items = get(secondaryMenu, ["items"], null);
  if (!items || !items.length) {
    return null;
  }
  return (
    <nav className={`${styles.navbar} ${isMegaMenuOpen ? "show-tablet-and-desktop" : ""}`}>
      <div className={`container ${styles["navbar-inner-wrap"]}`}>
        <Menus items={items} onMegaMenuToggle={onMegaMenuToggle} isMegaMenuOpen={isMegaMenuOpen} />
      </div>
    </nav>
  );
};

SecondaryMenubar.propTypes = {
  secondaryMenu: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        path: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            path: PropTypes.string
          })
        )
      })
    )
  }),
  isMegaMenuOpen: PropTypes.bool,
  onMegaMenuToggle: PropTypes.func,
  onSearchToggle: PropTypes.func,
  searchButtonRef: PropTypes.func
};

export default SecondaryMenubar;
