/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";

import styles from "./styles.m.css";

const SubscriptionLink = ({ href, text = "Subscribe to vikatan " }) => {
  return (
    <div className={styles["subs-link-wrapper"]}>
      <Link
        aria-label="Subscribe"
        className={`${styles["subscription-link"]} hidden-desktop`}
        rel="noopener noreferrer"
        target="_blank"
        href={"https://vikatanmobile.page.link/website_floating_button"}
        callback={() => {
          if ("ga" in window) {
            window.ga("send", "event", "app-install-header-mob", "Clicked", window.location.href);
          }
          if ("gtag" in window) {
            window.gtag("event", "app-install-header-mob", {
              event_category: "click",
              event_label: window.location.href
            });
          }
        }}
      >
        <div className={styles["app-install"]}>
          <div className={styles["subscribe-now"]}>
            1 மாத டிஜிட்டல் சந்தா முற்றிலும் இலவசம் <span className={styles["subscribe-text"]}> Install App </span>
          </div>
        </div>
      </Link>
    </div>
  );
};
SubscriptionLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string
};

export default SubscriptionLink;
