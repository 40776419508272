/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import styles from "./styles.m.css";
import { Link } from "@quintype/components";

export default class VikatanNews extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["news-category-grid"]}>
          <div className={styles["news-category-section"]}>
            <div className={styles["footer-header"]}>
              <span>vikatan News</span>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/news">Tamil News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/latest-news">Latest Tamil News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/news/india">India News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/news/tamilnadu">Latest News in Tamil nadu</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/news/world">World News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/business">Business News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/government-and-politics">Politics News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/news/environment">Environment News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/technology">Technology News Tamil</Link>
                </li>
              </ul>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://cinema.vikatan.com/">Cinema News</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/tamil-cinema">Tamil Cinema News</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/bollywood">Bollywood Cinema News</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/hollywood">Hollywood Cinema News</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/movie-review">Tamil Movie Reviews</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/television">Television News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/topics/television-serial">Tv Serial Latest News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/topics/web-series">Web Series News</Link>
                </li>
              </ul>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/spiritual">Spiritual News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/spiritual/temples">Temples Latest News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/topics/festival">Festivals News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/horoscope/rasipalan">Today Rasipalan</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/horoscope/gurupeyarchi">Gurupeyarchi Palangal</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/horoscope/sanipeyarchi">Sani Peyarchi Palangal</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/spiritual/astrology">Astrology in Tamil</Link>
                </li>
              </ul>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/vikatan-video">Vikatan TV</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/collection/cinema-vikatan-tv">Cinema Vikatan</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/collection/lifestyle-videos">Lifestyle News</Link>
                </li>
              </ul>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/business">Business News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/business/share-market">Share Market News in Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/topics/mutual-fund">Mutual Funds News in Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/business/real-estate">Real Estate News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/business/career">Career News Tamil</Link>
                </li>
              </ul>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://sports.vikatan.com/">Sports News</Link>
                </li>
                <li>
                  <Link href="https://sports.vikatan.com/">Sports News Tamil</Link>
                </li>
                <li>
                  <Link href="https://sports.vikatan.com/cricket">Cricket News Tamil</Link>
                </li>
                <li>
                  <Link href="https://sports.vikatan.com/ipl/ipl-2020">IPL News Tamil</Link>
                </li>
                <li>
                  <Link href="https://sports.vikatan.com/football">Football News Tamil</Link>
                </li>
              </ul>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/news/album">Photo Story</Link>
                </li>
                <li>
                  <Link href="https://cinema.vikatan.com/collection/cinema-album">Tamil Cinema Albums</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/collection/politics-album">Tamilnadu Politics Albums</Link>
                </li>
              </ul>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/pasumaivikatan">Agriculture News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/government-and-politics/agriculture">Agriculture News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/literature/animals">Animals News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/government-and-politics/environment">Environment News</Link>
                </li>
              </ul>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/automobile">Automobile News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/automobile/car">Latest Car News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/automobile/bike">Latest Bike News Tamil</Link>
                </li>
              </ul>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/health">Health News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/health">Latest Health News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/health/women">Women News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/health/kids">Kids News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/topics/health-tips">Health tips Tamil</Link>
                </li>
              </ul>
              <ul>
                <li className={styles["footer-title"]}>
                  <Link href="https://www.vikatan.com/lifestyle">Lifestyle News</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/lifestyle/travel">Travel News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/arts/literature">Literature News Tamil</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/food/food">Food News Tamil</Link>
                </li>
              </ul>
            </div>
            <div className={styles["news-category-div"]}>
              <ul>
                <li className={styles["footer-title"]}>Calculator</li>
                <li>
                  <Link href="https://www.vikatan.com/calculators/atal-pension-yojana-calculator">
                    Atal Pension Yojana
                  </Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/love-calculator">FLAMES - Calculator</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/common-assets/love-calculator-2022">Love Calculator</Link>
                </li>
                <li>
                  <Link href="https://www.vikatan.com/vikatan-friendship-calculator">Friendship Calculator</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { VikatanNews };
