import React from "react";
import { fetchVikatanCoins } from "../../../../../isomorphic/api";
class VikatanCoins extends React.Component {
  constructor(props) {
    super(props);
    this.state = { current: 0 };
  }

  // counter = (start, end, duration) => {
  //   let current = start;

  //   let range = end - start;

  //   let increment = end > start ? 1 : -1;

  //   let step = Math.abs(Math.floor(duration / range));

  //   let timer = setInterval(() => {
  //     current += increment;
  //     this.setState({ current });
  //     if (current === end) {
  //       clearInterval(timer);
  //     }
  //   }, step);
  // };

  componentDidMount() {
    global.userPromise.catch(() => ({})).then(user => {
      if (Object.keys(user).length !== 0 && user.loggedOut === false) {
        if (user) {
          fetchVikatanCoins()
            .then(res => {
              if (res && res.items.total_coins !== 0) {
                // this.counter(0, res && res.items.vikatan_coins, 3000);
                this.setState({ current: res && res.items.total_coins });
              }
            })
            .catch(console.error);
        }
      } else {
        // this.counter(0, 100, 3000);
        this.setState({ current: 100 });
      }
    });
  }

  render() {
    return <span>{this.state.current}</span>;
  }
}

export default VikatanCoins;
