/* eslint-disable global-require */
import { startApp } from "@quintype/framework/client/start";
import Cookies from "universal-cookie";
import "../assets/stylesheets/app.scss";
import { renderApplication, preRenderApplication } from "./render";
import { fetchEvAuthorize, fetchLocation } from "../isomorphic/api";
import wretch from "wretch";
import { get } from "lodash";

const cookies = new Cookies();

function enableHotReload(store) {
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./render", () => renderApplication(store));
  }
}

const CUSTOM_REDUCERS = {
  user(state = null, action) {
    switch (action.type) {
      case "ADD_USER":
        return { ...action.user };
      default:
        return state;
    }
  },
  storiesCounter(state = { remainingStoriesCount: null }, action) {
    switch (action.type) {
      case "UPDATE_STORIES_READ_STATS":
        return { remainingStoriesCount: action.remainingStoriesCount };
      default:
        return state;
    }
  },
  ShowPlayer(state = false, action) {
    switch (action.type) {
      case "SET_MINI_PLAYER_VISIBILITY":
        return action.payload;
      default:
        return state;
    }
  },
  PlayAudio(state = false, action) {
    switch (action.type) {
      case "AUDIO_PLAYING":
        return action.payload;
      default:
        return state;
    }
  },
  CurrentStory(state = null, action) {
    switch (action.type) {
      case "CURRENT_STORY":
        return action.payload;
      default:
        return state;
    }
  },
  CurrentCollection(state = {}, action) {
    switch (action.type) {
      case "CURRENT_COLLECTION":
        return action.payload;
      default:
        return state;
    }
  },
  ClosePlayer(state = false, action) {
    switch (action.type) {
      case "CLOSE_PLAYER":
        return action.payload;
      default:
        return state;
    }
  }
};

global.wretch = wretch;

global.qtNoAutomaticGATracking = true;

const evolokWithLogin = Promise.all([fetchEvAuthorize(), fetchLocation()]).then(res => {
  return res;
});

global.userPromise = new Promise((resolve, reject) => {
  evolokWithLogin.then(([segmentData, locationData]) => {
    const segments = segmentData.segments || [];
    const loggedOutStat = get(segmentData, ["loggedOut"], true);
    let user = get(segmentData, ["userData", "user"], {});
    let info = get(segmentData, ["userData", "info"], null);
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    user.location = locationData;
    user.segments = segments;

    if (user && user.userId && global.navigator && global.navigator.sendBeacon) {
      global.navigator.sendBeacon(`/api/vikatan/v1/ping-user/${user.userId}`);
    }

    if (info) {
      const exSS = get(info, ["secureSession", "sessionId"]);
      const evSS = get(info, ["mainSession", "sessionId"]);
      const userProfileStat = get(info, ["code"], "");

      if (userProfileStat === "USER_PROFILE_SUSPENDED") {
        cookies.set("vuid", "", { path: "/", maxAge: "", domain: ".vikatan.com" });
        return;
      }

      cookies.set("ev-guid", info.guid, { path: "/", expires: nextYear, domain: ".vikatan.com" });
      cookies.set("ev_ss", evSS, { path: "/", expires: nextYear, domain: ".vikatan.com" });
      cookies.set("ex_ss", exSS, { path: "/", domain: ".vikatan.com", expires: nextYear });
      global.localStorage.setItem("evolok:ev_session", JSON.stringify(info));
      global.location.reload(false);
    }

    if (user) {
      user.userType = segmentUserType(segments);
      user.loggedOut = loggedOutStat;

      resolve(user);
    } else {
      resolve(user);
    }
  });
});

const segmentUserType = segments => {
  let evUserType = "";
  switch (true) {
    case segments.includes("P"):
      evUserType = segments.find(x => x === "P");
      break;
    case segments.includes("T"):
      evUserType = segments.find(x => x === "T");
      break;
    case segments.includes("R"):
      evUserType = segments.find(x => x === "R");
      break;
    case segments.includes("E"):
      evUserType = segments.find(x => x === "E");
      break;
    case segments.includes("F"):
      evUserType = segments.find(x => x === "F");
      break;
    default:
      evUserType = "";
      break;
  }

  return evUserType || "";
};

global.paywallPromise = { type: "B", "std-link": "https://store.vikatan.com/?utm_source=standard_pw" };

startApp(renderApplication, CUSTOM_REDUCERS, {
  enableServiceWorker: process.env.NODE_ENV === "production",
  enableFCM: true,
  appVersion: require("../isomorphic/app-version"),
  preRenderApplication
}).then(enableHotReload);
