import React from "react";
import Media from "react-media";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./styles.m.css";
import { Link } from "@quintype/components";
import AppLinks from "../app-links";
import LatestIssues from "../latest-issues";
import SubscriptionLink from "../subscription-link";
import SocialLinks from "../social-links";
import Icon from "../../../atoms/icon";
import FullWidthSearch from "../../../rows/header/full-width-search";
// MegaMenu => Desktop full width dropdown menu that is collapsable in mobile

const MegaMenu = ({ menuGroup, socialLinks, appStoreLinks, latestIssues, className, subscriptionLink }) => {
  const menuItems = get(menuGroup, ["items"], null);
  if (!menuItems || !menuItems.length) {
    return null;
  }

  return (
    <div className={`${styles.base} ${className}`}>
      <div className="container">
        <FullWidthSearch isOpen={true} />
        <div className="row">
          <Media query="(max-width: 767px)">
            {matches =>
              matches ? (
                <React.Fragment>
                  {menuItems.map((menuItem, index) => (
                    <RenderMenuItem key={index} menuItem={menuItem} />
                  ))}
                  <div className={styles["menu-bottom"]}>
                    <div className={styles["space-between"]}>
                      <AppLinks appStoreLinks={appStoreLinks} />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {renderColumns(generateCols(menuItems.filter(menuItem => menuItem.title)))}
                </React.Fragment>
              )
            }
          </Media>
        </div>
      </div>
    </div>
  );
};

// generateCols => re-arrange the menuitems for desktop design

const generateCols = menuItems => {
  // create 6 columns using first 6 menuitems
  let menuCols = menuItems.splice(0, 6).map(menuItem => [menuItem]);
  // sort remaining menuitems in desc order
  menuItems = menuItems.sort((a, b) => b.items.length - a.items.length);
  // add each menu item to the column with smallest size
  menuItems.forEach(menuItem => {
    let colSizes = menuCols.map(menuCol => {
      return (
        menuCol.reduce((sum, col) => {
          return (sum += col.items.length);
        }, 0) +
        (menuCol.length - 1) * 2
      );
    });
    menuCols[colSizes.indexOf(Math.min.apply(null, colSizes))].push(menuItem);
  });
  return menuCols;
};

const renderColumns = menuCols => {
  return menuCols.map((menuCol, index) => (
    <div key={index} className={`${styles.column}`}>
      {menuCol.map((menuItem, index) => (
        <RenderMenuItem key={index} menuItem={menuItem} />
      ))}
    </div>
  ));
};

// RenderMenuItem => Render parent menu and it's child menu items

class RenderMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(function(prevState) {
      return { isToggleOn: !prevState.isToggleOn };
    });
  }
  render() {
    const menuItem = this.props.menuItem;
    const externalLink = menuItem.isExternalLink ? menuItem.path : null;
    const iconType = get(this.props, ["menuItem", "data", "icon-code"]) || "news";

    return (
      <div className={styles["menu-single-group"]}>
        <div className={styles["menu-single-group-headline-wrap"]}>
          <h4 className={styles["menu-single-group-headline"]}>
            <Icon type={iconType} className={styles.icon} />
            {menuItem.title !== "விகடன் குழுமம்" &&
            menuItem.title !== "பிரிண்ட் சந்தா / புக்ஸ்" &&
            menuItem.title !== "Love Calculator" &&
            menuItem.title !== "Vikatan Deals" ? (
              <Link
                aria-label={`${"Read More about"} ${menuItem.title}`}
                href={`${menuItem.path}?pfrom=mega-menu`}
                externalLink={externalLink}
              >
                {menuItem.title}
              </Link>
            ) : menuItem.title === "Vikatan Deals" ? (
              <a href="https://www.vikatan.com/vikatan-deals" rel="noopener noreferrer" target="_blank">
                {menuItem.title}
              </a>
            ) : (
              <a
                aria-label={`${"Read More about"} ${menuItem.title}`}
                href={`${menuItem.path}?pfrom=mega-menu`}
                externalLink={externalLink}
              >
                {menuItem.title}
              </a>
            )}
          </h4>

          {menuItem.items &&
            menuItem.items.length > 0 && (
              <button
                aria-label="Close"
                role="button"
                className={`${styles["close-button"]} ${this.state.isToggleOn ? styles["is-open"] : ""}`}
                onClick={this.handleClick}
              />
            )}
        </div>

        {menuItem.items &&
          menuItem.items.length > 0 && (
            <div
              className={`${styles["menu-single-group-items-wrap"]} ${this.state.isToggleOn ? styles["is-open"] : ""}`}
              onClick={this.handleClick}
            >
              <ul className={styles["menu-single-group-items"]}>
                {renderSubMenuItems(
                  menuItem.items.filter(item => item.title && item.path),
                  menuItem.title !== "விகடன் குழுமம்" && menuItem.title !== "Love Calculator"
                )}
              </ul>
            </div>
          )}
      </div>
    );
  }
}
RenderMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        path: PropTypes.string
      })
    )
  })
};

const renderSubMenuItems = (subMenuItems, isLink) => {
  return subMenuItems.map((subMenuItem, index) => {
    const externalLink = subMenuItem.isExternalLink ? subMenuItem.path : null;
    return (
      <li key={index} className={styles["menu-single-group-item"]}>
        {isLink ? (
          <Link
            aria-label={`${"Read full story: "} ${subMenuItem.title}`}
            href={`${subMenuItem.path}?pfrom=mega-menu`}
            className={styles["menu-single-group-item-link"]}
            externalLink={externalLink}
          >
            {subMenuItem.title}
          </Link>
        ) : (
          <a
            aria-label={`${"Read full story: "} ${subMenuItem.title}`}
            href={`${subMenuItem.path}?pfrom=mega-menu`}
            className={styles["menu-single-group-item-link"]}
          >
            {subMenuItem.title}
          </a>
        )}
      </li>
    );
  });
};

MegaMenu.propTypes = {
  menuGroup: PropTypes.shape(RenderMenuItem.propTypes),
  appStoreLinks: AppLinks.propTypes.appStoreLinks,
  socialLinks: SocialLinks.propTypes.socialLinks,
  latestIssues: LatestIssues.propTypes.latestIssues,
  className: PropTypes.string,
  subscriptionLink: PropTypes.shape(SubscriptionLink.propTypes)
};

export default MegaMenu;
