import { PAGE_TYPE } from "./constants";
import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "homelist", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "section", component: "SectionPage" },
    [PAGE_TYPE.BUNDLE_PAGE]: { chunk: "section", component: "BundlePage" },
    [PAGE_TYPE.MAGAZINE_PAGE]: { chunk: "magazine", component: "MagazineLandingPage" },
    [PAGE_TYPE.MAGAZINE_PAGE_BUNDLE]: { chunk: "magazine", component: "MagazineLandingPageBundlePage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "section", component: "TagPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "section", component: "AuthorPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "section", component: "SearchPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: "section", component: "CatalogPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_STATIC]: { chunk: "story", component: "StoryPageStatic" },
    [PAGE_TYPE.TOPICS_PAGE]: { chunk: "story", component: "TopicsPage" },
    [PAGE_TYPE.SITE_MAP]: { chunk: "story", component: "Sitemap" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "list", component: "HomePagePreview" },
    [PAGE_TYPE.RASIPALAN_PAGE]: { chunk: "story", component: "RasipalanLandingPage" },
    [PAGE_TYPE.RASIPALAN_DETAIL_PAGE]: { chunk: "story", component: "RasipalanDetailPage" },
    [PAGE_TYPE.GURUPEYARCHI_PAGE]: { chunk: "story", component: "GurupeyarchiLandingPage" },
    [PAGE_TYPE.GURUPEYARCHI_STORY_PAGE]: { chunk: "story", component: "GurupeyarchiStoryPage" },
    [PAGE_TYPE.MAGAZINE_LISTING_PAGE]: { chunk: "magazine", component: "MagazineIssueListingPage" },
    [PAGE_TYPE.MAGAZINE_ARCHIVE_SEARCH_PAGE]: { chunk: "magazine", component: "MagazineSearchArchivePage" },
    [PAGE_TYPE.SPECIAL_PAGE]: { chunk: "staticlist", component: "SpecialPage" },
    [PAGE_TYPE.IPL_PAGE]: { chunk: "section", component: "IplPage" },
    [PAGE_TYPE.WEBSTORIES_PAGE]: { chunk: "section", component: "WebstoriesPage" },
    [PAGE_TYPE.VIKATAN_SERIES_PAGE]: { chunk: "section", component: "VikatanSeriesPage" },
    [PAGE_TYPE.VI_BUNDLE_PAGE]: { chunk: "section", component: "ViBundlePage" },
    [PAGE_TYPE.SERIES_PAGE]: { chunk: "section", component: "SeriesLandingPage" },
    [PAGE_TYPE.VIDEO_PAGE]: { chunk: "section", component: "VideoPage" },
    [PAGE_TYPE.TAX_PAGE]: { chunk: "staticlist", component: "TaxPage" },
    [PAGE_TYPE.CONSTITUENCY_PAGE]: { chunk: "staticlist", component: "ConstituencyPage" },
    [PAGE_TYPE.LOKSABHA_ELECTION_PAGE]: { chunk: "staticlist", component: "LoksabhaDetailPage" },
    [PAGE_TYPE.AUDIO_PAGE]: { chunk: "staticlist", component: "AudioPage" },
    [PAGE_TYPE.ELECTION_PAGE]: { chunk: "staticlist", component: "ElectionPage" },
    [PAGE_TYPE.ELECTION_RESULT_PAGE]: { chunk: "staticlist", component: "ElectionPage" },
    [PAGE_TYPE.LOKSABHA_HOME_PAGE]: { chunk: "staticlist", component: "ElectionPage" },
    [PAGE_TYPE.LOKSABHA_CANDIDATES_PAGE]: { chunk: "staticlist", component: "LoksabhaCandidatesPage" },
    [PAGE_TYPE.FLAMES_PAGE]: { chunk: "staticlist", component: "FlamesPage" },
    [PAGE_TYPE.AKS_PAGE]: { chunk: "staticlist", component: "AksPage" },
    [PAGE_TYPE.STORY_FEED]: { chunk: "homelist", component: "HomePage" },
    [PAGE_TYPE.SHORTS_PAGE]: { chunk: "staticlist", component: "ShortsPage" },
    [PAGE_TYPE.CANDIDATE_PAGE]: { chunk: "staticlist", component: "CandidatePage" },
    [PAGE_TYPE.CANDIDATE_RESULT_PAGE]: { chunk: "staticlist", component: "CandidateResultPage" },
    [PAGE_TYPE.FORYOU_PAGE]: { chunk: "staticlist", component: "ForyouPage" },
    [PAGE_TYPE.RECOMMEND_PAGE]: { chunk: "staticlist", component: "RecommendPage" },
    [PAGE_TYPE.SITE_MAP_]: { chunk: "staticlist", component: "SiteMapPage" },
    [PAGE_TYPE.SITE_MAP_YEAR]: { chunk: "staticlist", component: "SpecificYearPage" },
    [PAGE_TYPE.SITE_MAP_MONTH]: { chunk: "staticlist", component: "SpecificMonthPage" },
    [PAGE_TYPE.SITE_MAP_DATE]: { chunk: "staticlist", component: "SpecificDateStories" },
    [PAGE_TYPE.SHORT_NEWS]: { chunk: "staticlist", component: "ShortNewsPage" },
    [PAGE_TYPE.GURUPEYARCHI_STATIC]: { chunk: "staticlist", component: "GurupeyarchiStaticPage" },
    [PAGE_TYPE.RAAGUKEDHUPEYARCHI_STATIC]: { chunk: "staticlist", component: "RaaguKedhuStaticPage" },
    [PAGE_TYPE.VIKATAN_DEALS]: { chunk: "deals", component: "VikatanDealsPage" },
    [PAGE_TYPE.VIKATAN_DEALS_PRODUCT]: { chunk: "deals", component: "VikatanDealsProductPage" },
    [PAGE_TYPE.VIKATAN_DEALS_CATEGORY_PAGE]: { chunk: "deals", component: "VikatanDealsCategoryPage" },
    [PAGE_TYPE.VIKATAN_DEALS_PRIVACYPOLICY]: { chunk: "deals", component: "VikatanDealsPrivacyPolicyPage" },
    [PAGE_TYPE.VIKATAN_DEALS_TERMS]: { chunk: "deals", component: "VikatanDealsTermsPage" },
    [PAGE_TYPE.VIKATAN_DEALS_CONTACTUS]: { chunk: "deals", component: "VikatanDealsContactUsPage" },
    [PAGE_TYPE.VIKATAN_DEALS_ABOUTUS]: { chunk: "deals", component: "VikatanDealsAboutUsPage" },
    [PAGE_TYPE.VIKATAN_DEALS_CSV_DOWNLOAD]: { chunk: "deals", component: "VikatanDealsCSVDownloadPage" },
    [PAGE_TYPE.AUTHOR_DEALS_PAGE]: { chunk: "deals", component: "VikatanDealsAuthorPage" },
    [PAGE_TYPE.APY_CALCULATOR]: { chunk: "staticlist", component: "APYCalculatorPage" },
    [PAGE_TYPE.PREMIUM_SERIES_PAGE]: { chunk: "dynamiclist", component: "PremiumSeriesPage" },
    [PAGE_TYPE.SHORT_STORIES_PAGE]: { chunk: "dynamiclist", component: "PremiumSeriesPage" },
    [PAGE_TYPE.CITY_COLLECTION_PAGE]: { chunk: "section", component: "SectionPage" },
    [PAGE_TYPE.GOOGLESEARCH_PAGE]: { chunk: "staticlist", component: "GoogleSearchPage" },
    [PAGE_TYPE.NEWS_PAGE]: { chunk: "section", component: "SectionPage" },
    [PAGE_TYPE.STORE_PAGE]: { chunk: "section", component: "StorePage" },
    [PAGE_TYPE.PDF_PAGE]: { chunk: "staticlist", component: "PDFPage" },
    [PAGE_TYPE.WORLDCUP_PAGE]: { chunk: "section", component: "WorldcupPage" },
    [PAGE_TYPE.ST_IPL_PAGE]: { chunk: "section", component: "STIPLPage" },
    [PAGE_TYPE.T20_WORLDCUP_PAGE]: { chunk: "section", component: "T20WorldcupPage" },
    [PAGE_TYPE.SCORE_PAGE]: { chunk: "section", component: "WCDetailPage" },
    [PAGE_TYPE.OLYMPICS_PAGE]: { chunk: "section", component: "OlympicsPage" },
    [PAGE_TYPE.AUDIOMAGAZINE_PAGE]: { chunk: "section", component: "AudioMagazinePage" },
    [PAGE_TYPE.AUDIOMAGAZINECOLLECTION_PAGE]: { chunk: "section", component: "AudioMagazineCollectionPage" },
    [PAGE_TYPE.AUDIOMAGAZINECOLLECTIONLIST_PAGE]: { chunk: "section", component: "AudioMagazineCollectionListPage" },
    [PAGE_TYPE.NT_PAGE]: { chunk: "section", component: "NTPage" },
    default: { chunk: "list", component: "NotFoundPage" }
  },
  {
    section: () => import(/* webpackChunkName: "section" */ "./component-bundles/section.js"),
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
    homelist: () => import(/* webpackChunkName: "homelist" */ "./component-bundles/homelist.js"),
    staticlist: () => import(/* webpackChunkName: "staticlist" */ "./component-bundles/staticlist.js"),
    dynamiclist: () => import(/* webpackChunkName: "dymaniclist" */ "./component-bundles/dymaniclist.js"),
    deals: () => import(/* webpackChunkName: "deals" */ "./component-bundles/deals.js"),
    magazine: () => import(/* webpackChunkName: "magazine" */ "./component-bundles/magazine.js")
  }
);

export { pickComponent, getChunkName };
