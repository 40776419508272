import React from "react";
import { LoadingIndicator } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import spinner from "../../../../assets/images/spinner.svg";

import styles from "./styles.m.css";

export const LoadingUI = () => (
  <div className={styles["load-wait"]}>
    <img src={assetify(spinner)} className={styles["loader-image"]} />
  </div>
);

export const LoadingIndicatorComponent = () => (
  <LoadingIndicator>
    <LoadingUI />
  </LoadingIndicator>
);
