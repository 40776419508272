import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../../../atoms/responsive-image-with-fallback";

import styles from "./styles.m.css";

const LatestIssuesBase = ({ latestIssues, config, className }) => {
  if (!latestIssues) return null;
  return (
    <div className={`${styles["latest-issues"]} ${className}`}>
      {latestIssues.slice(0, 5).map(latestIssue => {
        const headline = get(latestIssue, ["name"], "");
        const imageCaption = get(latestIssue, ["metadata", "cover-image", "caption"]) || headline;
        const coverImageS3Key = get(latestIssue, ["metadata", "cover-image", "cover-image-s3-key"], "");
        const coverImageMetaData = get(latestIssue, ["metadata", "cover-image", "cover-image-metadata"], "");
        const magazineEntity = get(latestIssue, ["metadata", "entities", "collectionEntities", "magazine", "0"], null);

        const magazine =
          magazineEntity && config.magazines ? config.magazines.find(m => m.id === magazineEntity.id) : null;
        return (
          <Link href={magazine ? latestIssue.pagePath : undefined} key={latestIssue.id}>
            <ResponsiveImageWithFallback
              className={styles["figure"]}
              slug={coverImageS3Key}
              metadata={coverImageMetaData}
              alt={imageCaption}
              imgParams={{ auto: ["format", "compress"] }}
              sources={[
                {
                  aspectRatio: [5, 7],
                  defaultWidth: 40,
                  widths: [50],
                  sizes: "(max-width: 500px) 50vw, (max-width: 768px) 50vw, 50vw"
                }
              ]}
            />
          </Link>
        );
      })}
    </div>
  );
};

const LatestIssues = connect(state => {
  return {
    config: get(state, ["qt", "config"])
  };
})(LatestIssuesBase);

LatestIssues.propTypes = {
  latestIssues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      metadata: PropTypes.shape({
        "cover-image": PropTypes.shape({
          "cover-image-metadata": PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number
          }),
          "cover-image-s3-key": PropTypes.string
        }),
        magazine: PropTypes.shape({
          name: PropTypes.string
        })
      })
    })
  )
};

LatestIssuesBase.propTypes = {
  ...LatestIssues.propTypes,
  config: PropTypes.shape({
    section: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  className: PropTypes.string
};

export default LatestIssues;
