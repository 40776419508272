import React from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import Modal from "react-modal";
import { get } from "lodash";
import { Icon } from "../../../atoms/icon";
import Cookie from "universal-cookie";
import styles from "./styles.m.css";

const cookie = new Cookie();

class IframeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false
    };
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  openModal = () => {
    this.setState({ popup: true });
  };

  closeModal = () => {
    this.setState({ popup: false });
  };

  render() {
    const userId = get(this.props.user, ["userId"], "");
    return (
      <article
        className={`infinity-template styles-m__nextstory-sec__3m2JZ ${styles["genail-outer"]} ${this.props.className}`}
      >
        <Modal
          isOpen={this.state.popup}
          onRequestClose={this.closeModal}
          // style={customStyles}
          contentLabel="Offers"
          ariaHideApp={false}
          className={styles["customStyles"]}
          overlayClassName={styles["OverlaycustomStyles"]}
        >
          <div className={styles["close-button-outer"]} onClick={this.closeModal}>
            <Icon type={"close"} className={styles["close-button"]} />
          </div>
          <Iframe
            frameBorder="0"
            className={styles["iframe-outer"]}
            src={`https://store.vikatan.com/vikatan-iframe-offers?utm_source=WKE2yrs_new&uid=${userId}&thinmintid=${cookie.get(
              "thinmint"
            )}&profile=full`}
          />
        </Modal>
      </article>
    );
  }
}

IframeModal.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default IframeModal;
