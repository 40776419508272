import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../atoms/icon";

import styles from "./styles.m.css";

const SocialLinks = ({ socialLinks, className = "" }) => {
  if (!socialLinks) {
    return null;
  }
  const socialComponentIcons = {
    "facebook-url": <Icon type="facebook" />,
    "twitter-url": <Icon type="twitter" />,
    "instagram-url": <Icon type="instagram" />
  };
  return (
    <ul className={`${styles["social-icons"]} ${className}`}>
      {Object.keys(socialComponentIcons).map(link => {
        return (
          socialLinks[link] && (
            <li key={link} className={styles["social-link-item"]}>
              <a
                aria-label={link}
                className={styles["social-link"]}
                target="_blank"
                rel="noopener  noreferrer"
                href={socialLinks[link]}
              >
                {socialComponentIcons[link]}
              </a>
            </li>
          )
        );
      })}
    </ul>
  );
};

SocialLinks.propTypes = {
  socialLinks: PropTypes.shape({
    "facebook-url": PropTypes.string,
    "twitter-url": PropTypes.string,
    "instagram-url": PropTypes.string
  }),
  className: PropTypes.string
};

export default SocialLinks;
