import React from "react";
import { createDfpAdComponent, refreshDfpAds } from "@quintype/components";
import { get } from "lodash";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import { produce } from "immer";
import { connect } from "react-redux";
import { getParentSection } from "../../../data/section";

const OUT_OF_PAGE_ADS = [
  "Inarticle_fork_cinema",
  "Vikatan_Mobile_Sticky_320x50",
  "Vikatan_Desktop_AP_ATF_STICKY_RIGHT_120x600",
  "Vikatan_Desktop_AP_ATF_RIGHT_300x250",
  "Home_MLB_Subscription",
  "Vikatan_Desktop_AP_ATF_RIGHT_300x250",
  "Vikatan_Desktop_AP_MID_RIGHT_300x250",
  "Vikatan_Desktop_AP_MID_STICKY_RIGHT_300x600"
];
const AD_TYPES_TO_EXCLUDE_FROM_INCREMENTAL_NAMES = [...OUT_OF_PAGE_ADS];

export const NEW_CONFIG = {
  Large_Leaderboard: {
    adUnit: "Large_Leaderboard",
    sizes: [[970, 90], [728, 90], [320, 100], [320, 50]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[970, 90], [728, 90]] },
      { viewport: [500, 0], sizes: [[320, 100], [320, 50]] },
      { viewport: [0, 0], sizes: [[320, 100], [320, 50]] }
    ]
  },
  Leaderboard: {
    adUnit: "Leaderboard",
    sizes: [[978, 90], [728, 90], [320, 100], [320, 50]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[978, 90], [728, 90]] },
      { viewport: [800, 0], sizes: [[320, 100], [320, 50]] },
      { viewport: [0, 0], sizes: [[320, 100], [320, 50]] }
    ]
  },
  Small_Leaderboard: {
    adUnit: "Small_Leaderboard",
    sizes: [[468, 60], [320, 50], [320, 100]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[468, 60]] },
      { viewport: [800, 0], sizes: [[320, 50], [320, 100]] },
      { viewport: [0, 0], sizes: [[320, 50], [320, 100]] }
    ]
  },
  Tower_Left: {
    adUnit: "Tower_Left",
    sizes: [[120, 600]]
  },
  Tower_Right: {
    adUnit: "Tower_Right",
    sizes: [[120, 600]]
  },
  Half_Page: {
    adUnit: "Half_Page",
    sizes: [[300, 600], [300, 250]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[300, 600]] },
      { viewport: [320, 0], sizes: [[300, 250]] },
      { viewport: [0, 0], sizes: [[300, 250]] }
    ]
  },
  Strip_Width: {
    adUnit: "Strip_Width",
    sizes: [[728, 50], [320, 100], [300, 250]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[728, 50]] },
      { viewport: [800, 0], sizes: [[320, 100]] },
      { viewport: [0, 0], sizes: [[300, 250]] }
    ]
  },
  Textlink: {
    adUnit: "Textlink",
    sizes: [[670, 80], [320, 80]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[670, 80]] },
      { viewport: [800, 0], sizes: [[320, 80]] },
      { viewport: [0, 0], sizes: [[320, 80]] }
    ]
  },
  Rectangle: {
    adUnit: "Rectangle",
    sizes: [[300, 250]]
  },
  Home_MLB_Subscription: {
    adUnit: "Home_MLB_Subscription",
    sizes: [[320, 70], [420, 70]]
  },
  Mobile_Leaderboard: {
    adUnit: "Mobile_Leaderboard",
    sizes: [[320, 100]]
  },
  Full_Page: {
    adUnit: "Full_Page",
    sizes: [[300, 900], [300, 250], [300, 600]]
  },
  Vikatan_widget: {
    adUnit: "Vikatan_widget",
    sizes: [[300, 250]]
  },
  Inarticle_fork_cinema: {
    adUnit: "Inarticle_fork_cinema",
    sizes: [[1, 1]]
  },
  Interstitial: {
    adUnit: "Interstitial",
    sizes: [[1, 1]]
  },
  Roadblock: {
    adUnit: "Roadblock",
    sizes: [[1, 1]]
  },
  Inarticle_fork: {
    adUnit: "Inarticle_fork",
    sizes: [[1, 1]]
  },
  Automated: {
    adUnit: "Automated",
    sizes: [[1, 1]]
  },
  test_new_1x1: {
    adUnit: "test_new_1x1",
    sizes: [[1, 1]]
  },
  Sticker_mcanvas: {
    adUnit: "Sticker_mcanvas",
    sizes: [[1, 1]]
  },
  Subscription_Lightbox: {
    adUnit: "Subscription_Lightbox",
    sizes: [[1, 1]]
  },
  Mobile_lightbox: {
    adUnit: "Mobile_lightbox",
    sizes: [[1, 1]]
  },
  test_300x250: {
    adUnit: "test_300x250",
    sizes: [[300, 250]]
  },
  test_300x250_2: {
    adUnit: "test_300x250_2",
    sizes: [[300, 250]]
  },
  test_300x250_3: {
    adUnit: "test_300x250_3",
    sizes: [[300, 250]]
  },
  test_728x90: {
    adUnit: "test_728x90",
    sizes: [[728, 90]]
  },
  test_978x90: {
    adUnit: "test_978x90",
    sizes: [[978, 90]]
  },
  test_300x600: {
    adUnit: "test_300x600",
    sizes: [[300, 600]]
  },
  VDO_AI_1: {
    adUnit: "VDO_AI_1",
    sizes: [[1, 1]]
  },
  Article_MLB_Subscription: {
    adUnit: "Article_MLB_Subscription",
    sizes: [[468, 60], [300, 100]],
    viewPortSizeMapping: [{ viewport: [980, 0], sizes: [[468, 60]] }, { viewport: [800, 0], sizes: [[300, 100]] }]
  },
  Leaderboard_new: {
    adUnit: "Leaderboard_new",
    sizes: [[320, 100], [728, 90]],
    viewPortSizeMapping: [
      { viewport: [980, 0], sizes: [[728, 90]] },
      { viewport: [320, 0], sizes: [[320, 100]] },
      { viewport: [0, 0], sizes: [[320, 100]] }
    ]
  },
  Vikatan_Desktop_AP_ATF_728x90: {
    adUnit: "Vikatan_Desktop_AP_ATF_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Mobile_AP_TOP_320x100: {
    adUnit: "Vikatan_Mobile_AP_TOP_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Desktop_AP_STICKY_ATF_LEFT_120x600: {
    adUnit: "Vikatan_Desktop_AP_STICKY_ATF_LEFT_120x600",
    sizes: [[120, 600]]
  },
  Vikatan_Desktop_AP_MID_300x250: {
    adUnit: "Vikatan_Desktop_AP_MID_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_MID_468x60: {
    adUnit: "Vikatan_Desktop_AP_MID_468x60",
    sizes: [[468, 60]]
  },
  Vikatan_Mobile_AP_BTF_320x100: {
    adUnit: "Vikatan_Mobile_AP_BTF_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Desktop_AP_ATF_RIGHT_300x250: {
    adUnit: "Vikatan_Desktop_AP_ATF_RIGHT_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_MID_STICKY_RIGHT_300x600: {
    adUnit: "Vikatan_Desktop_AP_MID_STICKY_RIGHT_300x600",
    sizes: [[300, 600]]
  },
  Vikatan_Desktop_AP_ATF_STICKY_RIGHT_120x600: {
    adUnit: "Vikatan_Desktop_AP_ATF_STICKY_RIGHT_120x600",
    sizes: [[120, 600]]
  },
  Vikatan_Desktop_AP_MID_300x600: {
    adUnit: "Vikatan_Desktop_AP_MID_300x600",
    sizes: [[300, 600]]
  },
  Vikatan_Desktop_AP_MID_RIGHT_300x250: {
    adUnit: "Vikatan_Desktop_AP_MID_RIGHT_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_MID1_468x60: {
    adUnit: "Vikatan_Desktop_AP_MID1_468x60",
    sizes: [[468, 60]]
  },
  Vikatan_Desktop_AP_Inarticle1_300x250: {
    adUnit: "Vikatan_Desktop_AP_Inarticle1_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_Inarticle2_300x250: {
    adUnit: "Vikatan_Desktop_AP_Inarticle2_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_Inarticle3_300x250: {
    adUnit: "Vikatan_Desktop_AP_Inarticle3_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_Inarticle4_300x250: {
    adUnit: "Vikatan_Desktop_AP_Inarticle4_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_AP_MID_320x100: {
    adUnit: "Vikatan_Mobile_AP_MID_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_AP_Inarticle1_300x250: {
    adUnit: "Vikatan_Mobile_AP_Inarticle1_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_AP_Inarticle2_300x250: {
    adUnit: "Vikatan_Mobile_AP_Inarticle2_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_AP_Inarticle3_300x250: {
    adUnit: "Vikatan_Mobile_AP_Inarticle3_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_AP_Inarticle4_300x250: {
    adUnit: "Vikatan_Mobile_AP_Inarticle4_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_Sticky_320x50: {
    adUnit: "Vikatan_Mobile_Sticky_320x50",
    sizes: [[320, 50]]
  },
  Vikatan_Mobile_AP_BTF2_320x100: {
    adUnit: "Vikatan_Mobile_AP_BTF2_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Interstitial_1x1: {
    adUnit: "Vikatan_Interstitial_1x1",
    sizes: [[1, 1]]
  },
  Vikatan_Mcanvas_1x1: {
    adUnit: "Vikatan_Mcanvas_1x1",
    sizes: [[1, 1]]
  },
  Vikatan_Desktop_HP_ATF_728x90: {
    adUnit: "Vikatan_Desktop_HP_ATF_728x90",
    sizes: [[970, 90], [728, 90]]
  },
  Vikatan_Desktop_HP_ATF_RIGHT_300x250: {
    adUnit: "Vikatan_Desktop_HP_ATF_RIGHT_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_HP_MID_728x90: {
    adUnit: "Vikatan_Desktop_HP_MID_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_MID2_728x90: {
    adUnit: "Vikatan_Desktop_HP_MID2_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_MID3_728x90: {
    adUnit: "Vikatan_Desktop_HP_MID3_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_MID4_728x90: {
    adUnit: "Vikatan_Desktop_HP_MID4_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_MID5_728x90: {
    adUnit: "Vikatan_Desktop_HP_MID5_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_MID6_728x90: {
    adUnit: "Vikatan_Desktop_HP_MID6_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_BTF_728x90: {
    adUnit: "Vikatan_Desktop_HP_BTF_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_BTF2_728x90: {
    adUnit: "Vikatan_Desktop_HP_BTF2_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Desktop_HP_BTF3_728x90: {
    adUnit: "Vikatan_Desktop_HP_BTF3_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_Mobile_HP_TOP_320x100: {
    adUnit: "Vikatan_Mobile_HP_TOP_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_ATF_300x250: {
    adUnit: "Vikatan_Mobile_HP_ATF_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_HP_MID_320x100: {
    adUnit: "Vikatan_Mobile_HP_MID_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Desktop_AP_TOP_420x70: {
    adUnit: "Vikatan_Desktop_AP_TOP_420x70",
    sizes: [[420, 70]]
  },
  Vikatan_Mobile_HP_MID2_320x100: {
    adUnit: "Vikatan_Mobile_HP_MID2_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_MID3_320x100: {
    adUnit: "Vikatan_Mobile_HP_MID3_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_MID4_320x100: {
    adUnit: "Vikatan_Mobile_HP_MID4_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_MID5_320x100: {
    adUnit: "Vikatan_Mobile_HP_MID5_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_BTF_320x100: {
    adUnit: "Vikatan_Mobile_HP_BTF_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_BTF2_320x100: {
    adUnit: "Vikatan_Mobile_HP_BTF2_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_BTF3_320x100: {
    adUnit: "Vikatan_Mobile_HP_BTF3_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Mobile_HP_BTF4_320x100: {
    adUnit: "Vikatan_Mobile_HP_BTF4_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Desktop_SP_ATF_728x90: {
    adUnit: "Vikatan_Desktop_SP_ATF_728x90",
    sizes: [[970, 90], [728, 90]]
  },
  Vikatan_Mobile_SP_TOP_320x100: {
    adUnit: "Vikatan_Mobile_SP_TOP_320x100",
    sizes: [[320, 100]]
  },
  Vikatan_Desktop_SP_STICKY_ATF_LEFT_120x600: {
    adUnit: "Vikatan_Desktop_SP_STICKY_ATF_LEFT_120x600",
    sizes: [[120, 600]]
  },
  Vikatan_Desktop_SP_STICKY_ATF_RIGHT_120x600: {
    adUnit: "Vikatan_Desktop_SP_STICKY_ATF_RIGHT_120x600",
    sizes: [[120, 600]]
  },
  Vikatan_Desktop_SP_ATF_Right_300x250: {
    adUnit: "Vikatan_Desktop_SP_ATF_Right_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_SP_ATF2_Right_300x250: {
    adUnit: "Vikatan_Desktop_SP_ATF2_Right_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_SP_MID_Right_300x250: {
    adUnit: "Vikatan_Desktop_SP_MID_Right_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_SP_BTF_728x90: {
    adUnit: "Vikatan_Desktop_SP_BTF_728x90",
    sizes: [[728, 90]]
  },
  Vikatan_mrec_mid_SP: {
    adUnit: "Vikatan_mrec_mid_SP",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_SP_ATF_300x250: {
    adUnit: "Vikatan_Mobile_SP_ATF_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Mobile_SP_MID_300x250: {
    adUnit: "Vikatan_Mobile_SP_MID_300x250",
    sizes: [[300, 250]]
  },
  Vikatan_Desktop_AP_Inbetweengallery_300x250: {
    adUnit: "Vikatan_Desktop_AP_Inbetweengallery_300x250",
    sizes: [[300, 250]]
  },
  test_320x50: {
    adUnit: "test_320x50",
    sizes: [[320, 50]]
  }
};

function getDfpAdContent(config) {
  return createDfpAdComponent({
    defaultNetworkID: "3849069",
    config: config,
    collapseEmptyDivs: true,
    targeting: function(state) {
      const params = {
        ...config.targetting,
        pageType: get(state, ["qt", "pageType"]),
        host: get(global, ["location", "hostname"])
      };

      if (
        get(state, ["qt", "pageType"]) === "story-page" &&
        get(state, ["qt", "data", "story", "metadata", "sponsored-by"])
      ) {
        params.sponsor = get(state, ["qt", "data", "story", "metadata", "sponsored-by"]);
        return params.sponsor;
      }

      return params;
    }
  });
}

let counterByAdUnitName = {};

class DfpAdBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adUnit: null,
      userType: "F"
    };
  }

  shouldLoadAd() {
    // hide tower ads if not enough space to show them
    if (["Tower_Left", "Tower_Right"].includes(this.props.adtype) && global.innerWidth < 1412) {
      return false;
    }

    // hide ad in desktop if hidden-desktop class is there
    if ((this.props.className || "").includes("hidden-desktop") && global.innerWidth > 767) {
      return false;
    }

    // hide ad in mobile if hidden-mobile class is there
    if ((this.props.className || "").includes("hidden-mobile") && global.innerWidth < 768) {
      return false;
    }

    return true;
  }

  getNextAdUnitName() {
    if (this.state.adUnit) {
      return this.state.adUnit;
    }

    if (!this.shouldLoadAd()) {
      return null;
    }

    if (AD_TYPES_TO_EXCLUDE_FROM_INCREMENTAL_NAMES.includes(this.props.adtype)) {
      return this.props.adtype;
    }

    return this.props.adtype;
  }

  componentWillUnmount() {
    if (!this.state.adUnit) {
      return null;
    }
    const adUnitName = this.state.adUnit
      .split("-")
      .slice(0, -1) // remove the counter value
      .join("-");
    counterByAdUnitName[adUnitName] && --counterByAdUnitName[adUnitName];
  }

  componentDidMount() {
    global.userPromise.catch(() => ({})).then(user => {
      this.setState({ userType: user.userType });
      this.setState({ adUnit: this.getNextAdUnitName() });
    });
  }

  shouldComponentUpdate() {
    return !this.state.adUnit;
  }

  render() {
    if (this.props.disableAds || !this.state.adUnit) {
      return <div className={this.props.className} />;
    }

    const adtype = get(this.props, ["adtype"], null);
    const hidetitle = get(this.props, ["hidetitle"], false);
    if (!adtype || !NEW_CONFIG[adtype]) {
      return null;
    }

    const newConfig = produce(NEW_CONFIG, draft => {
      draft[adtype]["adUnit"] = `${this.state.adUnit}`;
      draft[adtype]["slotId"] = this.props.slotId;
      draft["targetting"] =
        this.props.placement === "header"
          ? {
              Magazine_leaderboard: [
                "anandavikatan",
                "avalkitchen",
                "avalmanamagal",
                "chuttivikatan",
                "avalvikatan",
                "diwalimalar",
                "doctorvikatan",
                "juniorvikatan",
                "motorvikatan",
                "nanayamvikatan"
              ]
            }
          : undefined;
    });

    const DfpAdContent = getDfpAdContent(newConfig);
    return (
      <React.Fragment>
        {this.state.userType === "P" || this.state.userType === "T" || this.state.userType === "R" ? (
          <div />
        ) : (
          <div>
            <div className={`${styles["ad"]} ${this.props.className}`}>
              {!hidetitle && <AdText adUnit={this.state.adUnit} />}
              <DfpAdContent {...this.props} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

DfpAdBase.propTypes = {
  className: PropTypes.string,
  adUnit: PropTypes.string,
  placement: PropTypes.string,
  slotId: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  const section = get(state, ["qt", "data", "section"]);
  const story = get(ownProps, ["story"]);
  let sectionName;
  if (section) {
    let parentSection = getParentSection(section);
    if (!parentSection) {
      parentSection = section;
    }
    sectionName = get(parentSection, ["slug"]);
  }
  if (story) {
    let parentSection = getParentSection(story.sections[0]);
    if (!parentSection) {
      parentSection = story.sections[0];
    }
    sectionName = get(parentSection, ["slug"]);
  }
  return {
    disableAds: get(state, ["qt", "config", "disableAds"]),
    sectionName
  };
}

export const DfpAd = connect(mapStateToProps)(DfpAdBase);

const AdText = props => {
  return (
    <div className={styles["ad-content"]}>
      <span styleName="ad-text" className={styles["ad-text"]}>
        {" "}
        ADVERTISEMENT{" "}
      </span>
    </div>
  );
};

AdText.propTypes = {
  adUnit: PropTypes.string
};

export { refreshDfpAds };
