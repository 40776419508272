/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { get } from "lodash";
import Icon from "../../../atoms/icon";

import styles from "./styles.m.css";

function UserLogin({ user, vikatanHosts = {} }) {
  const loggedOut = get(user, ["loggedOut"], true);
  if (!loggedOut) {
    return (
      <div className={styles.user}>
        <Media query="(max-width: 767px)">
          {matches =>
            matches ? (
              <Icon type="user-square" className={styles["logout-icon"]} />
            ) : (
              <span className={`hidden-mobile ${styles.logout}`}>
                <span className={styles["reader-text"]}>My Account</span>
                <Icon type="downarrow" className={styles["login-arrow"]} />
              </span>
            )
          }
        </Media>
        {user && (
          <div className={styles["myaccount-dropdown-wrap"]}>
            <ul className={styles["myaccount-dropdown"]}>
              <div className={`${styles["mbl-dd-txt"]} hidden-desktop`}>
                <span className={styles["reader-text"]}>My Account</span>
                <Icon type="downarrow" className={styles["login-arrow"]} />
              </div>
              <li className={styles["myaccount-item"]}>
                <a href={`${vikatanHosts.userProfile}`} className={styles["myaccount-action-item"]}>
                  Your Profile
                </a>
              </li>
              <li className={styles["myaccount-item"]}>
                <a
                  href={global.location && `${vikatanHosts.logout}?redirect_url=${global.location.href}`}
                  className={styles["myaccount-action-item"]}
                >
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <a
        aria-label="User Login"
        href={global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`}
        className={styles.user}
      >
        <Icon className={`${styles["login"]} hidden-desktop`} type={"user-square"} />{" "}
        <span className={`hidden-mobile  ${styles.login}`}>
          <span className={styles["login-text"]}>Login</span>
        </span>
      </a>
    );
  }
}

UserLogin.propTypes = {
  user: PropTypes.object,
  vikatanHosts: PropTypes.shape({
    login: PropTypes.string,
    subscription: PropTypes.string,
    userProfile: PropTypes.string,
    logout: PropTypes.string
  })
};

export default UserLogin;
