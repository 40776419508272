import React, { useState, useEffect, useRef } from "react";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../assets/images/vikatan-logo.png";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";
import AudioMagazinePlayer from "../../pages/story/audiomagazine-story";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link, WithHostUrl } from "@quintype/components";
import { LoadingIndicatorComponent } from "../../atoms/loading-indicator";

const VikatanAudioMagazineHeader = user => {
  const [isAudioMenuVisible, setIsAudioMenuVisible] = useState(false);
  const [userType, setUserType] = useState("F");
  const [userEmail, setuserEmail] = useState("");
  // const [userName, setuserName] = useState("");
  // const [userJoinDate, setuserJoinDate] = useState("");
  const [loggedOut, setloggedOut] = useState(true);

  const menuRef = useRef(null);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(get(user, ["userType"]));
      user && setuserEmail(get(user, ["userEmail"]));
      user && setuserEmail(get(user, ["userEmail"]));
      //   user && setuserName(get(user, ["username"]));
      //  user && setuserJoinDate(get(user, ["userJoinDate"]));
      user && setloggedOut(get(user, ["loggedOut"]));
    });
  }, []);

  useEffect(
    () => {
      const handleClickOutside = event => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsAudioMenuVisible(false); // Close the menu if clicked outside
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [menuRef]
  );

  const toggleMenu = () => {
    setIsAudioMenuVisible(!isAudioMenuVisible);
  };

  return (
    <div className={styles["audio-header"]}>
      <AudioMagazinePlayer />
      <LoadingIndicatorComponent />
      <div className={styles["audio-header-conatiner"]}>
        <div className={styles["audio-header-grid"]}>
          <div className={styles["menu-logo"]}>
            <div className={styles["hamburger"]} onClick={toggleMenu}>
              <span />
              <span />
              <span />
            </div>

            <WithHostUrl>
              {({ primaryHostUrl }) => (
                <Link
                  className={styles["home-link"]}
                  aria-label="Vikatan"
                  href={primaryHostUrl ? primaryHostUrl + "/vikatan-play" + "/" : "/"}
                >
                  <img
                    alt="Vikatan"
                    src={assetify(logo)}
                    className={styles["brand-logo"]}
                    width="240px"
                    height="50px"
                  />
                  {/* <img
                    alt="Vikatan"
                    src={assetify(logo)}
                    className={styles["brand-logo-icon"]}
                    width="40px"
                    height="50px"
                  /> */}
                </Link>
              )}
            </WithHostUrl>
            {/* <a className={styles["home-link"]} aria-label="Vikatan" href="https://www.vikatan.com/vikatan-play">
              <img alt="Vikatan" src={assetify(logo)} className={styles["brand-logo"]} width="240px" height="50px" />
            </a> */}
          </div>
          <div />
          {/* <div className={styles["audio-search-bar"]}>
            <input type="search" />
            <Icon type="search" />
          </div> */}
          <div className={styles["audio-header-cta"]}>
            {/* <div className={styles["trail-cta"]}>
              <a href="#">Get 30 Days Trial</a>
            </div> */}
            {loggedOut ? (
              <div className={styles["login-cta"]}>
                <a href={global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`}>
                  <Icon type="icon-login" />
                  <span className={styles["login-text"]}>Login</span>
                </a>
              </div>
            ) : (
              <div>
                {userType === "P" || userType === "T" || userType === "R" ? (
                  <div className={`${styles["game-btn-des"]}`}>
                    <Icon type={"icon-premium"} className={styles["premium-icon"]} />
                    <span className={styles["premium-text"]}>Premium</span>
                  </div>
                ) : (
                  <div className={`${styles["subscribe-btn-des"]}`}>
                    <a
                      href="https://vikatan.com/store?utm_source=direct_web&utm_medium=subscribe_button&utm_campaign=subscribe_button"
                      className={styles["readapp-link"]}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      SUBSCRIBE
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isAudioMenuVisible && (
          <div ref={menuRef} className={styles["audio-header-popup"]}>
            <div>
              <div className={styles["audio-header-popup-header"]}>
                <a className={styles["home-link"]} aria-label="Vikatan" href="https://www.vikatan.com/">
                  <img
                    alt="Vikatan"
                    src={assetify(logo)}
                    className={styles["brand-logo"]}
                    width="240px"
                    height="50px"
                  />
                </a>
                <div className={styles["close-div"]} onClick={toggleMenu}>
                  X
                </div>
              </div>
              <ul className={styles["user-details"]}>
                <li>
                  <div>
                    {loggedOut ? (
                      <a
                        href={global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`}
                        className={`${styles["trail-cta"]} ${styles["user-trail-cta"]}`}
                      >
                        <Icon type="icon-login" />
                        <span className={styles["user-cta-text"]}>Login</span>
                      </a>
                    ) : userType === "P" || userType === "T" || userType === "R" ? (
                      // <div className={`${styles["game-btn-des"]}`}>
                      //   <Icon type={"icon-premium"} className={styles["premium-icon"]} />
                      //   <span className={styles["premium-text"]}>Premium</span>
                      // </div>
                      <div />
                    ) : (
                      <div className={`${styles["subscribe-btn-des"]}`}>
                        <a
                          href="https://vikatan.com/store?utm_source=direct_web&utm_medium=subscribe_button&utm_campaign=subscribe_button"
                          className={styles["readapp-link"]}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          SUBSCRIBE
                        </a>
                      </div>
                    )}
                  </div>
                </li>

                {userEmail && (
                  <li>
                    <strong>User’s email</strong>
                    <p>{userEmail}</p>
                  </li>
                )}
                {/* <li>
                  <strong>Phone number</strong>
                  <p>9876543210</p>
                </li>
                <li>
                  <strong>Subscription pack name</strong>
                  <p>All Magazine</p>
                </li>
                <li>
                  <strong>Sub start date</strong>
                  <p>JAN 20 2024</p>
                </li>
                <li>
                  <strong>End date</strong>
                  <p>May 20 2026</p>
                </li> */}
                <li>
                  <strong>Contact us</strong>
                  <p>
                    <a href="mailto:webmaster@vikatan.com">webmaster@vikatan.com</a>
                  </p>
                </li>
              </ul>
            </div>
            <div className={styles["audio-footer"]}>
              <div className={styles["play-store-icons"]}>
                <a href="https://play.google.com/store/apps/details?id=com.vikatanapp&hl=en&mt=8">
                  <Icon type="icon-audio-play-store" />
                </a>
                <a href="https://apps.apple.com/in/app/vikatan/id452698974">
                  <Icon type="icon-audio-app-store" />
                </a>
              </div>
              <p>© vikatan 2024. All Rights Reserved</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: get(state, ["user"]) };
}

export default connect(mapStateToProps)(VikatanAudioMagazineHeader);
