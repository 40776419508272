import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../atoms/icon";

import styles from "./styles.m.css";

const MyVikatanBadge = ({ isMyVikatan = false, className = "" }) => {
  if (!isMyVikatan) {
    return null;
  } else {
    return (
      <span className={styles["premium-badge"]}>
        <Icon type="myvikatanbadge" className={styles["myvikatanbadge"]} />
      </span>
    );
  }
};

MyVikatanBadge.propTypes = {
  isMyVikatan: PropTypes.bool,
  className: PropTypes.string
};

export { MyVikatanBadge };
