import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import assetify from "@quintype/framework/assetify";
import appstore from "../../../../../assets/images/app-store-icon.png";
import playstore from "../../../../../assets/images/play-store-icon.png";

const AppLinks = ({ appStoreLinks, className }) => {
  const AppStoreIcons = {
    "play-store": (
      <img
        alt="Vikatan Playstore"
        width="26px"
        height="26px"
        src={assetify(playstore)}
        className={styles["store-icons"]}
      />
    ),
    "app-store": (
      <img
        alt="Vikatan Appstore"
        width="26px"
        height="26px"
        src={assetify(appstore)}
        className={styles["store-icons"]}
      />
    )
  };
  return (
    <ul className={`${styles["app-icons"]} ${className}`}>
      {Object.keys(appStoreLinks).map(link => {
        return (
          appStoreLinks[link] && (
            <li key={link} className={styles["app-link-item"]}>
              <a
                aria-label={link}
                href={appStoreLinks[link]}
                target="_blank"
                rel="noopener  noreferrer"
                className={styles["app-link"]}
              >
                {AppStoreIcons[link]}
              </a>
            </li>
          )
        );
      })}
    </ul>
  );
};

AppLinks.propTypes = {
  appStoreLinks: PropTypes.shape({
    "play-store": PropTypes.string,
    "app-store": PropTypes.string
  }),
  className: PropTypes.string
};

export default AppLinks;
