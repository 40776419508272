import React, { useEffect } from "react";

const CompanionAd = () => {
  useEffect(() => {
    // Ensure googletag is available before using it
    const googletag = global.googletag || {};

    window.googletag = window.googletag || { cmd: [] };

    googletag.cmd.push(() => {
      // Define the ad slot
      googletag
        .defineSlot("/3849069/Web", [300, 250], "companionAd")
        .addService(googletag.companionAds())
        .addService(googletag.pubads());

      // Refresh unfilled slots
      googletag.companionAds().setRefreshUnfilledSlots(true);

      // Enable the services
      googletag.enableServices();
    });
  }, []); // Empty dependency array means this runs once when the component mounts

  return <div id="companionAd" style={{ width: "300px", height: "250px" }} />;
};

export default CompanionAd;
