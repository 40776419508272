import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./styles.m.css";
import { Link } from "@quintype/components";
import Icon from "../../../atoms/icon";

const Menus = ({ items, onMegaMenuToggle, isMegaMenuOpen, itemCount = 11 }) => {
  let menus = items
    .filter(item => item.title && item.path)
    .slice(0, itemCount)
    .map(item => {
      const externalLink = item.isExternalLink ? item.path : null;
      const iconType = get(item, ["data", "icon-code"]) || "news";
      const subMenu = get(item, ["items"], []);
      const color = get(item, ["data", "color"]);
      return (
        <li itemProp="name" role="menuitem" key={item.id} className={`${styles["menu-item"]} ${styles["border-top"]}`}>
          <Link
            itemprop="url"
            aria-label={`${"Read More about"} ${item.title}`}
            className={styles["menu-item-link"]}
            href={`${item.url || item.path}?pfrom=header-menu`}
            externalLink={externalLink}
          >
            <Icon type={iconType} className={styles.icon} />
            {item.title} {color === "#00000F" && <span className={styles["new-menu"]}>New</span>}
          </Link>
          {subMenu.length > 0 && (
            <ul className={styles["sub-menu"]}>
              {subMenu.map(subitem => (
                <li key={subitem.id} className={styles["sub-menu-item"]}>
                  <Link
                    aria-label={`${"Read More about"} ${subitem.title}`}
                    className={styles["sub-menu-link"]}
                    href={`${subitem.path || subitem.url}?pfrom=header-submenu`}
                    externalLink={subitem.isExternalLink && subitem.path}
                    target={
                      subitem.path === "https://english.vikatan.com/" ||
                      subitem.path === "https://hindi.vikatan.com/" ||
                      subitem.path === "https://telugu.vikatan.com/"
                        ? "_blank"
                        : ""
                    }
                  >
                    {subitem.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    });
  return (
    <ul
      itemScope
      id="topnav"
      itemType="https://schema.org/SiteNavigationElement"
      role="menu"
      className={`${styles.menus}`}
    >
      {menus}
      <li
        className={`${styles["menu-item"]} ${styles["navbar-megamenu-toggle"]} show-tablet-and-desktop`}
        role="menuitem"
      >
        <button aria-label="toggle" role="button" className={styles["menu-item-link"]} onClick={onMegaMenuToggle}>
          {isMegaMenuOpen ? <Icon type={"menu-search"} /> : <Icon type={"menu-search"} />}
        </button>
      </li>
    </ul>
  );
};

Menus.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  onMegaMenuToggle: PropTypes.func,
  isMegaMenuOpen: PropTypes.bool,
  itemCount: PropTypes.number
};

const NavMenubar = ({ menuGroup, isMegaMenuOpen, onMegaMenuToggle, onSearchToggle, searchButtonRef }) => {
  const items = get(menuGroup, ["items"], null);
  if (!items || !items.length) {
    return null;
  }
  return (
    <nav className={`${styles.navbar} ${isMegaMenuOpen ? "show-tablet-and-desktop" : ""}`}>
      <div className={`container ${styles["navbar-inner-wrap"]}`}>
        <Menus items={items} onMegaMenuToggle={onMegaMenuToggle} isMegaMenuOpen={isMegaMenuOpen} />
      </div>
    </nav>
  );
};

NavMenubar.propTypes = {
  menuGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        path: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            path: PropTypes.string
          })
        )
      })
    )
  }),
  isMegaMenuOpen: PropTypes.bool,
  onMegaMenuToggle: PropTypes.func,
  onSearchToggle: PropTypes.func,
  searchButtonRef: PropTypes.func
};

export default NavMenubar;
