import React from "react";
import PropTypes from "prop-types";
import { SearchBox, Link } from "@quintype/components";
import Icon from "../../../atoms/icon";

import styles from "./styles.m.css";
import { DeepBI } from "../../../../integrations";

function clickEvent(kw) {
  DeepBI.PingSearchClicked({ q: kw });
}

function DrawForm({ children }) {
  const searchParam = children.props.value || "";
  return (
    <React.Fragment>
      <label htmlFor="searchForm" className={styles.label} key={"lable2"}>
        {children}
      </label>
      <button
        aria-label="search"
        role="button"
        className={styles.button}
        type="submit"
        key={"btn2"}
        onClick={() => clickEvent(searchParam)}
      >
        <Icon type="search" key={"search2"} />
      </button>
    </React.Fragment>
  );
}

DrawForm.propTypes = {
  children: PropTypes.element
};

class FullWidthSearch extends React.Component {
  focusInput() {
    if (this.props.isOpen) {
      document.getElementById("searchForm").focus();
    }
  }
  componentDidMount() {
    this.focusInput();
  }
  componentDidUpdate() {
    this.focusInput();
  }
  render() {
    return (
      <div ref={this.props.searchRef} className={`${styles.base} ${this.props.isOpen ? styles["is-open"] : ""}`}>
        <div className={`${"container"} ${styles["search-wrap"]}`}>
          <SearchBox
            template={DrawForm}
            inputId="searchForm"
            placeholder="Search"
            onSubmitHandler={this.props.buttonClick}
          />
        </div>
        <Link href="/vikatan-search">
          <div className={`${styles["search-text-div"]}`}>
            <Icon type="search" key={"search2"} className={`${styles["search-icon"]}`} />
            <span className={`${styles["search-text"]}`}>Search</span>
          </div>
        </Link>
      </div>
    );
  }
}

FullWidthSearch.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  searchRef: PropTypes.func,
  buttonClick: PropTypes.func
};

export default FullWidthSearch;
