import React from "react";
import PropTypes from "prop-types";
import Icon from "../../../atoms/icon";
import styles from "./styles.m.css";

const DisableAdBlocker = props => {
  if (!props.show) {
    return null;
  }
  React.useEffect(() => {
    if (global.webengage && global.webengage.track) {
      global.webengage.track("Adblocker displayed", {});
    }
  }, []);
  const AdBlockerFun = value => {
    if (value === "login") {
      if ("ga" in window) {
        window.ga("send", "event", "loginAdblocker", "click", window.location.href);
      }
      if ("gtag" in window) {
        window.gtag("event", "loginAdblocker", {
          event_category: "click",
          event_label: window.location.href
        });
      }
      if (global.webengage && global.webengage.track) {
        global.webengage.track("Login from Adblocker", {});
      }
    }
    if (value === "subscribe") {
      if ("ga" in window) {
        window.ga("send", "event", "subscribeAdblocker", "click", window.location.href);
      }
      if ("gtag" in window) {
        window.gtag("event", "subscribeAdblocker", {
          event_category: "click",
          event_label: window.location.href
        });
      }
      if (global.webengage && global.webengage.track) {
        global.webengage.track("Subscribe button click from adblock", {});
      }
    }
  };
  return (
    <div className={styles["popup-wrapper-adb"]}>
      <div className={`${styles["popup-div-adb"]} ${styles["ab-blocker"]}`}>
        <div className={styles["close-button-adb-outer-adb"]} onClick={props.onClose}>
          <Icon type={"close"} className={styles["close-button-adb"]} />
        </div>
        <div className={styles["adblocker-header"]}>
          <h5>ஹாய்! Ad Blocker யூஸ் பண்றீங்களா?</h5>
        </div>
        <div className={styles["adblocker-content"]}>
          <div className={styles["blocker-image"]}>
            {" "}
            <img
              src="https://gumlet.assettype.com/vikatan/2021-11/eb42d8c9-3d33-4274-9d3a-f5495c95ac77/ads.png"
              alt=""
            />
            <h2>
              It looks like you are
              <br />
              using an Ad blocker!
            </h2>
          </div>
          <ul>
            <li className="hidden-mobile">
              {" "}
              <img
                src="https://gumlet.assettype.com/vikatan/2021-11/cb6f907f-df8c-4e51-b4e0-18129e41cbcc/speaker.png"
                alt=""
              />{" "}
              <span>
                தொடர்ந்து விகடனை படிக்க <br /> Ad Blocker-ல் விகடன் வலைதளத்தை <strong>exclude</strong> செய்யுங்கள்.{" "}
                <span className={styles["instructions"]} onClick={props.onSwitch}>
                  <strong>வழிமுறைகள் இங்கே!</strong>
                </span>
              </span>{" "}
            </li>
            <li className="hidden-desktop">
              {" "}
              <img
                src="https://gumlet.assettype.com/vikatan/2021-11/cb6f907f-df8c-4e51-b4e0-18129e41cbcc/speaker.png"
                alt=""
              />{" "}
              <span>
                தொடர்ந்து விகடனை படிக்க <br /> Ad Blocker-ல் விகடன் வலைதளத்தை <strong>exclude</strong> செய்யுங்கள்.
              </span>{" "}
            </li>
            <li>
              {" "}
              <img
                src="https://gumlet.assettype.com/vikatan/2021-11/cb6f907f-df8c-4e51-b4e0-18129e41cbcc/speaker.png"
                alt=""
              />{" "}
              <span>
                விளம்பரங்கள் இல்லாமல் படிக்க <strong>சப்ஸ்கிரைப் செய்யுங்கள்!</strong>{" "}
              </span>{" "}
            </li>
          </ul>
        </div>
        <div className={styles["cta-btns"]}>
          {" "}
          <a
            href={`https://login.vikatan.com/?redirect_url=${encodeURIComponent(
              window.location.href
            )}?pfrom=disable-ad-blocker`}
            className={styles["outline"]}
            onClick={() => AdBlockerFun("login")}
          >
            Login
          </a>{" "}
          <a
            href="https://store.vikatan.com/vikatan-special-offers?offer_code=Retargeting_1yr&utm_source=direct_web&utm_medium=ad_block_paywall&utm_campaign=ad_block_paywall/?pfrom=disable-ad-blocker"
            className={styles["active"]}
            onClick={() => AdBlockerFun("subscribe")}
          >
            Subscribe
          </a>{" "}
        </div>
      </div>
    </div>
  );
};

DisableAdBlocker.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSwitch: PropTypes.func
};

export { DisableAdBlocker };
