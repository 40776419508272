import { get } from "lodash";
import { BREAK_POINT_MIN, BREAK_POINT_TABLET, MEDIA_TABLET, BREAK_POINT_MAX } from "./constant";
import produce from "immer";
import { btoa, atob } from "isomorphic-base64";
export function getSubstring(string, limit) {
  let slicedString = string.substr(0, limit);
  return string.length > limit ? slicedString.substr(0, slicedString.lastIndexOf(" ")) + "..." : string;
}

export function copyToClipboard(text) {
  if (!document) {
    return null;
  }
  let dummy = document.createElement("input");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  let copiedStatus = document.execCommand("copy");
  document.body.removeChild(dummy);
  return copiedStatus;
}

export const getStoryHeadline = story => {
  return get(story, ["alternative", "home", "default", "headline"]) || story.headline;
};

export const getStoryData = (story, isAlternativeImageFirst = true) => {
  let storyData = {
    imageS3Key: null
  };

  if (!story) {
    return storyData;
  }

  storyData = getMainData(story);
  if (!storyData.imageS3Key) {
    storyData = getAlternativeData(story);
  }
  return storyData;
};

const getAlternativeData = story => {
  let storyData = {};
  storyData.headline = getStoryHeadline(story);
  storyData.imageS3Key = get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], null);
  storyData.imageCaption =
    get(story, ["alternative", "home", "default", "hero-image", "hero-image-caption"]) || storyData.headline;

  storyData.imageAttribution = get(
    story,
    ["alternative", "home", "default", "hero-image", "hero-image-attribution"],
    null
  );

  storyData.imageMetadata = get(story, ["alternative", "home", "default", "hero-image", "hero-image-metadata"], null);
  return storyData;
};

const getMainData = story => {
  let storyData = {};
  storyData.headline = getStoryHeadline(story);
  storyData.imageS3Key = get(story, ["hero-image-s3-key"], null);
  storyData.imageCaption = get(story, ["hero-image-caption"]) || storyData.headline;
  storyData.imageMetadata = story["hero-image-metadata"];
  storyData.imageAttribution = get(story, ["hero-image-attribution"], null);
  return storyData;
};

export const generateImageSources = (
  mobile = { aspectRatio: [1, 1], screenWidthCoverage: 1, srcSetCount: 3 },
  desktop = { aspectRatio: [1, 1], screenWidthCoverage: 1, srcSetCount: 3 }
) => {
  return [
    Object.assign(
      {},
      generateImageSource(desktop.screenWidthCoverage, BREAK_POINT_TABLET, BREAK_POINT_MAX, desktop.srcSetCount),
      {
        aspectRatio: desktop.aspectRatio,
        media: MEDIA_TABLET
      }
    ),
    Object.assign(
      {},
      generateImageSource(mobile.screenWidthCoverage, BREAK_POINT_MIN, BREAK_POINT_TABLET, mobile.srcSetCount),
      {
        aspectRatio: mobile.aspectRatio
      }
    )
  ];
};

const generateImageSource = (
  screenWidthCoverage = 1,
  min = BREAK_POINT_MIN,
  max = BREAK_POINT_MAX,
  srcSetCount = 3
) => {
  const minImageSize = min * screenWidthCoverage;
  const maxImageSize = max * screenWidthCoverage;
  const srcSetDiff = (maxImageSize - minImageSize) / srcSetCount;

  const widths = [];
  for (let i = 1; i <= srcSetCount; i++) {
    widths.push(parseInt(minImageSize + srcSetDiff * i));
  }

  const sizes =
    "(max-width: " + max + "px) " + parseInt(screenWidthCoverage * 100) + "vw, " + widths[widths.length - 1] + "px";

  let source = {
    defaultWidth: widths[Math.floor(widths.length / 2)],
    widths: widths,
    sizes: sizes
  };
  return source;
};

export function getSubscriptionURL(host, collection) {
  if (collection) {
    return `${host}?utm_source=direct_web&utm_medium=magazine_page&utm_campaign=` + collection.slug;
  }

  return host;
}

export function getIssueUrlBySlug(slug) {
  if (!slug) return null;

  let tokens = slug.split("-");

  const date = new Date(tokens.splice(tokens.length - 3, 3).join("-"));
  const magazineUrl = tokens.join("");

  const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
  const dateString = !isNaN(date.getTime())
    ? [date.getFullYear(), monthNames[date.getMonth()], date.getDate()].join("-")
    : "";

  return "/" + magazineUrl.replace("/", "") + "/" + dateString.replace("/", "");
}

const bulletImages = [
  /^dot_15\d+/,
  /^dot\d+(_\d+_)?/,
  /^red_(glossy_)?dot[\d_]*/i,
  /^bullet/,
  /^(rig(ht)?_)?arrow/,
  /^cross[\d_]*.jpg/,
  /^plus[\d_]*.jpg/,
  /^star[\d_]*.jpg/
];

export function removeBulletImagesFromStory(story) {
  return produce(story, draft => {
    if (draft && draft.cards) {
      draft.cards.forEach(updateCard);
    }
    return draft;
  });

  function updateCard(cardDraft) {
    if (!cardDraft["story-elements"]) {
      return;
    }
    cardDraft["story-elements"] = cardDraft["story-elements"].reduce(filterValidElements, []);
  }

  function filterValidElements(acc, element, index, storyElements) {
    if (element.type === "image" && isBulletImage(element["image-s3-key"])) {
      const nextElement = storyElements[index + 1];
      if (nextElement && nextElement.type === "text" && nextElement.text.startsWith("<p>")) {
        nextElement.text = nextElement.text.replace("<p>", "<p>• ");
      }
    } else {
      acc.push(element);
    }

    return acc;
  }

  function isBulletImage(s3Key) {
    const parts = s3Key.split("/");
    const imageName = parts[parts.length - 1];
    return bulletImages.find(regex => regex.test(imageName));
  }
}

export function checkAppSlug() {
  if (global.location) {
    const params = global.location.href.split("?");
    const regExp = new RegExp(/source=app/);
    const queryParams = get(params, [1]);
    if (queryParams && regExp.test(queryParams)) {
      return true;
    }
  }
  return false;
}

export function checkQueryString(param) {
  if (global.location) {
    const urlParams = new URLSearchParams(global.location.search);
    return urlParams.get(param) || false;
  }
  return false;
}

export function checkAppPramSlug() {
  if (global.location) {
    const params = global.location.href.split("?");
    const subdomain = global.location.hostname.split(".")[0];
    const regExp = new RegExp(/source=newsite/);
    const queryParams = get(params, [1]);
    if (queryParams && regExp.test(queryParams)) {
      return subdomain || true;
    }
  }
  return false;
}

export function dmStaticVideoUrl() {
  // static ID
  var items = ["x7zz5sn", "x7zzjxv", "x7zz43a", "x7zu389"];
  return items[Math.floor(Math.random() * items.length)];
}

export function dmChannelPick(section = []) {
  const arr = [
    { id: "x2fim74", name: "av", match: ["sports", "sports-news"] },
    {
      id: "x2efkvb",
      name: "vikatan",
      match: [
        "arts",
        "living-things",
        "events",
        "government-and-politics",
        "oddities",
        "science",
        "social-affairs",
        "technology",
        "anniversaries",
        "news",
        "literature",
        "literature",
        "cartoon",
        "miscellaneous",
        "kids",
        "miscellaneous",
        "medicine",
        "men",
        "career",
        "travel",
        "international",
        "nostalgia",
        "humoursatire",
        "viral",
        "literature",
        "humoursatire"
      ]
    },
    {
      id: "x2fidh2",
      name: "aval",
      match: ["health", "food"]
    },
    {
      id: "x2fjvzq",
      name: "cinema",
      match: [
        "entertainment",
        "celebrity",
        "television",
        "bollywood",
        "celebrity",
        "kollywood",
        "music",
        "hollywood",
        "tamil-cinema",
        "television"
      ]
    },
    { id: "x2fi3xl", name: "motor", match: ["automobile"] },
    { id: "x2fi7bq", name: "nanayam", match: ["business"] },
    {
      id: "x2fim7g",
      name: "say swag",
      match: ["fashion", "lifestyle"]
    },
    {
      id: "x2fiddo",
      name: "sakthi vikatan",
      match: ["spiritual", "temples"]
    },
    { id: "x2ficl0", name: "pasumai", match: ["agriculture"] }
  ];
  var vid = "x2efkvb";
  for (const i in arr) {
    if (arr[i].match.includes(section)) {
      vid = arr[i].id;
      break;
    }
  }

  return vid;
}

export function isGaa() {
  // Validate GAA params.
  const params = getQueryParams();
  if (!params.gaa_at) {
    return false;
  }
  if (!params.gaa_n) {
    console.error("SwG Entitlements: The `gaa_n` URL param is missing.");
    return false;
  }
  if (!params.gaa_sig) {
    console.error("SwG Entitlements: The `gaa_sig` URL param is missing.");
    return false;
  }
  if (!params.gaa_ts) {
    console.error("SwG Entitlements: The `gaa_ts` URL param is missing.");
    return false;
  }
  if (parseInt(params.gaa_ts, 16) < Date.now() / 1000) {
    console.error(
      "SwG Entitlements: The `gaa_ts` URL param should " + "contain a hex string timestamp which points to the future."
    );
    return false;
  }

  // Validate referrer.
  // NOTE: This regex was copied from SwG's AMP extension. https://github.com/ampproject/amphtml/blob/c23bf281f817a2ee5df73f6fd45e9f4b71bb68b6/extensions/amp-subscriptions-google/0.1/amp-subscriptions-google.js#L56
  const GOOGLE_DOMAIN_RE = /(^|\.)google\.(com?|[a-z]{2}|com?\.[a-z]{2}|cat)$/;
  const referrer = getAnchorFromUrl(document.referrer);
  if (referrer.protocol !== "https" || !GOOGLE_DOMAIN_RE.test(referrer.hostname)) {
    // Real publications should bail if this referrer check fails.
    // This script is only logging a warning for metering demo purposes.
    console.warn(
      `SwG Entitlements: This page's referrer ("${referrer.origin}") can't ` +
        "grant Google Article Access. Real publications should bail if this " +
        "referrer check fails."
    );
  }

  return true;
}

export function getAnchorFromUrl(url) {
  const a = document.createElement("a");
  a.href = url;
  return a.hostname;
}

export function getQueryParams() {
  const queryParams = {};
  global &&
    global.location &&
    global.location.search
      .substring(1)
      .split("&")
      .forEach(pair => {
        const parts = pair.split("=");
        queryParams[parts[0]] = parts[1];
      });
  return queryParams;
}

export function checkMagazineUTM() {
  if (global.location) {
    const params = global.location.href.split("?");
    const subdomain = global.location.hostname.split(".")[0];
    const regExp = new RegExp(/utm_source=magazine-page/);
    const queryParams = get(params, [1]);
    if (queryParams && regExp.test(queryParams)) {
      return subdomain || true;
    }
  }
  return false;
}

export function getEntities(collection, magazines) {
  const collectionData = get(collection, [0], {});
  const issueEntity = get(collectionData, ["metadata", "entities", "collectionEntities", "magazine", 0], {});
  const issueEntityId = get(collectionData, ["metadata", "entities", "collectionEntities", "magazine", 0, "id"], {});
  const magazineEnName = magazines.find(magazine => magazine.id === issueEntityId);
  collectionData.magazineEnName = magazineEnName.name;
  collectionData.magazineEntity = issueEntity;
  return collectionData;
}

export function EncryptData(secretKey, data) {
  let encrypted = "";
  for (let i = 0; i < data.length; i++) {
    const keyChar = secretKey.charCodeAt(i % secretKey.length);
    encrypted += String.fromCharCode(data.charCodeAt(i) ^ keyChar);
  }

  const base64Encoded = btoa(encrypted);
  // Remove all equals signs
  const withoutEquals = base64Encoded.replace(/=/g, "");
  return withoutEquals;
}

export function DecryptData(secretKey, encryptedData) {
  // First, decode the Base64-encoded encrypted data
  let decodedData = atob(encryptedData);

  let decrypted = "";
  for (let i = 0; i < decodedData.length; i++) {
    const keyChar = secretKey.charCodeAt(i % secretKey.length);
    decrypted += String.fromCharCode(decodedData.charCodeAt(i) ^ keyChar);
  }

  return decrypted;
}

// Custom btoa function
export function customBtoa(input) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let output = "";
  let padding = 0;
  let buffer = 0;
  let bitsInBuffer = 0;

  for (let i = 0; i < input.length; i++) {
    buffer = (buffer << 8) | input.charCodeAt(i);
    bitsInBuffer += 8;

    while (bitsInBuffer >= 6) {
      const index = (buffer >> (bitsInBuffer - 6)) & 0x3f;
      output += chars[index];
      bitsInBuffer -= 6;
    }
  }

  // Handle remaining bits
  if (bitsInBuffer > 0) {
    padding = 6 - bitsInBuffer;
    buffer <<= padding;
    const index = buffer & 0x3f;
    output += chars[index];
  }

  return output;
}

// Custom atob function
export function customAtob(input) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let output = "";
  let buffer = 0;
  let bitsInBuffer = 0;

  for (let i = 0; i < input.length; i++) {
    const charValue = chars.indexOf(input.charAt(i));
    if (charValue === -1) {
      throw new Error("Invalid character in input");
    }

    buffer = (buffer << 6) | charValue;
    bitsInBuffer += 6;

    if (bitsInBuffer >= 8) {
      output += String.fromCharCode((buffer >> (bitsInBuffer - 8)) & 0xff);
      bitsInBuffer -= 8;
    }
  }

  return output;
}

export function getPWD(key, data) {
  return DecryptData(key, data);
}

export function pdfIssueDateFormat(issueDate, format = "normal") {
  const originalDate = new Date(issueDate);

  // Convert the original date to the desired time zone
  const utcTimestamp = originalDate.getTime();
  const customDate = new Date(utcTimestamp + originalDate.getTimezoneOffset() * 60000);

  // Format the date using the customDateFormat function
  const formattedDate = customDateFormat(customDate, format);

  return formattedDate;
}

// Create a function to format the date in a custom way
function customDateFormat(date, format = "normal") {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const monthDigit = (date.getMonth() + 1).toString().padStart(2, "0");

  if (format === "normal") {
    return `${formattedDay}/${monthDigit}/${year}`;
  } else {
    return `${day}-${month.toLowerCase()}-${year}`;
  }
}

export function convertInteger(numString) {
  const numWithoutCommas = numString && numString.replace(/,/g, ""); // Remove commas
  const numInteger = parseInt(numWithoutCommas, 10); // Parse as integer
  return numInteger;
}

export function formatNumberWithCommas(num) {
  const numString = num.toString();
  const parts = numString.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  return parts.join(".");
}
