import React from "react";
import PropTypes from "prop-types";
import Icon from "../../../atoms/icon";
import styles from "./styles.m.css";

const Content = props => {
  if (props.current === 1) {
    return (
      <div className={styles["tab-content-adb"]}>
        <div className={styles["screen-img"]}>
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/692681b5-7fcf-4406-ab9a-9cf92d1e51b6/screen.png"
            alt=""
          />
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/71690521-9944-4344-9dad-eca7a098f1e2/blocker_icon.png"
            alt=""
            className={`${styles["blocker-icon"]} ${styles["icon-xs"]}`}
          />
        </div>
        <ul>
          <li>
            <span>1.</span>
            <span>
              Click on the AdBlock icon{" "}
              <img
                src="https://gumlet.assettype.com/vikatan/2021-11/71690521-9944-4344-9dad-eca7a098f1e2/blocker_icon.png"
                alt=""
                className={styles["icon-xs"]}
              />{" "}
              in the browser extension area on the upper right-hand corner. (You may see a small number covering part of
              the icon.)
            </span>
          </li>
          <li>
            <span>2.</span>
            <span>
              Click on the <strong>Always</strong> button to pause the AD blocker for the Vikatan site permanently.
            </span>
          </li>
          <li>
            <span>3.</span>
            <span>
              Click the <strong>Refresh</strong> button.
            </span>
          </li>
        </ul>
      </div>
    );
  }
  if (props.current === 2) {
    return (
      <div className={styles["tab-content-adb"]}>
        <div className={styles["screen-img"]}>
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/692681b5-7fcf-4406-ab9a-9cf92d1e51b6/screen.png"
            alt=""
          />
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/695e20c2-d031-428d-a069-d9b8d178dd83/blockerplus_icon.png"
            alt=""
            className={`${styles["blocker-icon"]} ${styles["icon-xs"]}`}
          />
        </div>
        <ul>
          <li>
            <span>1.</span>
            <span>
              Click on the Adblock Plus icon{" "}
              <img
                src="https://gumlet.assettype.com/vikatan/2021-11/695e20c2-d031-428d-a069-d9b8d178dd83/blockerplus_icon.png"
                alt=""
                className={styles["icon-xs"]}
              />{" "}
              in the browser extension area on the upper right-hand corner. (You may see a small number covering part of
              the icon.)
            </span>
          </li>
          <li>
            <span>2.</span>
            <span>Turn off the toggle button to pause the ADBlockPlus extension for the Vikatan site.</span>
          </li>
          <li>
            <span>3.</span>
            <span>
              Click the <strong>Refresh</strong> button.
            </span>
          </li>
        </ul>
      </div>
    );
  }
  if (props.current === 3) {
    return (
      <div className={styles["tab-content-adb"]}>
        <div className={styles["screen-img"]}>
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/692681b5-7fcf-4406-ab9a-9cf92d1e51b6/screen.png"
            alt=""
          />
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/613166d1-de4e-41d5-9ef8-d2f5989bcc54/uo_icon.png"
            alt=""
            className={`${styles["blocker-icon"]} ${styles["icon-xs"]}`}
          />
        </div>
        <ul>
          <li>
            <span>1.</span>
            <span>
              Click on the uBlock Origin icon{" "}
              <img
                src="https://gumlet.assettype.com/vikatan/2021-11/613166d1-de4e-41d5-9ef8-d2f5989bcc54/uo_icon.png"
                alt=""
                className={styles["icon-xs"]}
              />{" "}
              in the browser extension area on the upper right-hand corner. (You may see a small number covering part of
              the icon.)
            </span>
          </li>
          <li>
            <span>2.</span>
            <span>
              Click on the blue “power” button. Now it turn to grey, indicating that ads in the Vikatan site are no
              longer longer being blocked.
            </span>
          </li>
          <li>
            <span>3.</span>
            <span>
              Click the <strong>Refresh</strong> button.
            </span>
          </li>
        </ul>
      </div>
    );
  }
  if (props.current === 4) {
    return (
      <div className={styles["tab-content-adb"]}>
        <div className={styles["screen-img"]}>
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/692681b5-7fcf-4406-ab9a-9cf92d1e51b6/screen.png"
            alt=""
          />
          <img
            src="https://gumlet.assettype.com/vikatan/2021-11/613166d1-de4e-41d5-9ef8-d2f5989bcc54/uo_icon.png"
            alt=""
            className={`${styles["blocker-icon"]} ${styles["icon-xs"]}`}
          />
        </div>
        <ul>
          <li>
            <span>1.</span>
            <span>
              Click the icon of the ad blocker extension installed on your browser. You&apos;ll usually find this icon
              upper right-hand corner of your screen. You may have more than one ad blocker installed.
            </span>
          </li>
          <li>
            <span>2.</span>
            <span>
              Follow the instructions for disabling the ad blocker on the site you&apos;re viewing. You may have to
              select a menu option or click a button.
            </span>
          </li>
          <li>
            <span>3.</span>
            <span>
              Refresh the page, either by following prompts or clicking your browser&apos;s “refresh” or “reload”
              button.
            </span>
          </li>
        </ul>
      </div>
    );
  }
};

const DisablingInsturction = props => {
  let [content, setContent] = React.useState(1);
  if (!props.show) {
    return null;
  }
  return (
    <div className={styles["popup-wrapper-adb"]}>
      <div className={`${styles["popup-div-adb"]} ${styles["ab-blocker"]}`}>
        <div className={styles["adblock-extension"]}>
          <h2>Which of these extensions do you have?</h2>
          <div className={styles["close-button-adb-outer-adb"]} onClick={props.onClose}>
            <Icon type={"close"} className={styles["close-button-adb"]} />
          </div>
          <div className={styles["tab-container-adb"]}>
            <ul className={styles["tabs"]}>
              <li className={`${content === 1 ? styles["current"] : ""}`} onClick={() => setContent(1)}>
                {" "}
                <img
                  src="https://gumlet.assettype.com/vikatan/2021-11/733434b1-ed8c-4c87-9401-27d620773588/adblock.png"
                  alt=""
                />{" "}
                <span>AdBlock</span>{" "}
              </li>
              <li className={`${content === 2 ? styles["current"] : ""}`} onClick={() => setContent(2)}>
                {" "}
                <img
                  src="https://gumlet.assettype.com/vikatan/2021-11/9787d3de-ac8f-4e3e-ae35-b38a0487a839/adblock_plus.png"
                  alt=""
                />
                <span>Adblock Plus</span>
              </li>
              <li className={`${content === 3 ? styles["current"] : ""}`} onClick={() => setContent(3)}>
                <img
                  src="https://gumlet.assettype.com/vikatan/2021-11/a44336c5-57a5-4119-b298-1efd0df9affc/uo.png"
                  alt=""
                />
                <span>uBlock Origin</span>
              </li>
              <li className={`${content === 4 ? styles["current"] : ""}`} onClick={() => setContent(4)}>
                <img
                  src="https://gumlet.assettype.com/vikatan/2021-11/84c0c8c7-7aaf-403c-9e69-aa20dd34fb7a/origin.png"
                  alt=""
                />
                <span>Others</span>
              </li>
            </ul>
            <Content current={content} />
          </div>
        </div>
        <div className={styles["cta-btns"]}>
          {" "}
          <a
            href=""
            className={styles["active"]}
            onClick={() => {
              if ("ga" in window) {
                window.ga("send", "event", "refreshAdblocker", "click", window.location.href);
              }
              if ("gtag" in window) {
                window.gtag("event", "refreshAdblocker", {
                  event_category: "click",
                  event_label: window.location.href
                });
              }
            }}
          >
            Refresh page
          </a>{" "}
        </div>
      </div>
    </div>
  );
};

DisablingInsturction.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export { DisablingInsturction };
