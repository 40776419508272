import React from "react";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../assets/images/vikatan-logo.png";
import styles from "./styles.m.css";

const PDFHeader = () => {
  return (
    <div className={styles["pdf-header"]}>
      <a className={styles["home-link"]} aria-label="Vikatan" href="https://www.vikatan.com/">
        <img alt="Vikatan" src={assetify(logo)} className={styles["brand-logo"]} width="240px" height="50px" />
      </a>
    </div>
  );
};

export default PDFHeader;
