import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { checkAppSlug } from "../../utils/utils";
import { Link, WithHostUrl } from "@quintype/components";
import VikatanNews from "./vikatan-news/vikatanNews";
import { Icon } from "../../atoms/icon";
import CookieConsent from "../../atoms/cookie-consent";
import { DisableAdBlocker } from "./ad-bocker-disable";

import styles from "./styles.m.css";
import { DisablingInsturction } from "./blocker-disable-instruct";

const MenuGroup = ({ menuGroup }) => {
  return (
    <div className={styles["menugroup-links-wrap"]}>
      {menuGroup.items.slice(0, 5).map(item => (
        <div key={item.id} className={styles["menugroup-links-column"]}>
          <h5 className={styles["heading"]}>
            {item.path ? (
              <a
                aria-label={`Read More about ${item.title}`}
                href={item.path}
                externalLink={item.isExternalLink ? item.path : null}
              >
                {item.title}
              </a>
            ) : (
              item.title
            )}
          </h5>
          <ul className={styles["menugroup-links"]}>
            {item.items &&
              item.items.map(menu => (
                <li key={menu.id} className={styles["menugroup-links-item"]}>
                  {menu.title === "Vikatan Deals" ? (
                    <a
                      href="https://www.vikatan.com/vikatan-deals"
                      className={styles["menugroup-link"]}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {menu.title}
                    </a>
                  ) : (
                    <a
                      aria-label={`Read More about" ${menu.title}`}
                      className={styles["menugroup-link"]}
                      href={menu.path || "#"}
                      externalLink={item.isExternalLink ? item.path : null}
                    >
                      {menu.title}
                    </a>
                  )}
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const menuItemShape = {
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const MenuItem = PropTypes.shape(menuItemShape);

MenuItem.items = PropTypes.arrayOf(MenuItem);

MenuGroup.propTypes = {
  menuGroup: PropTypes.shape({
    items: PropTypes.arrayOf(MenuItem)
  })
};

const SocialLinks = ({ socialLinks }) => {
  const socialComponentIcons = {
    "facebook-url": <Icon type="facebook" />,
    "google-plus-url": <Icon type="gplus" />,
    "rss-url": <Icon type="rss" />,
    "twitter-url": <Icon type="twitter" />,
    "linkedin-url": <Icon type="linkedin" />,
    "youtube-url": <Icon type="youtube" />,
    "sharechat-url": <Icon type="sharechat" />
  };
  return (
    <div className={styles["social-links-wrap"]}>
      <h5 className={styles["heading"]}>Connect</h5>
      <ul className={styles["social-icons"]}>
        {Object.keys(socialLinks).map(link => {
          return (
            socialLinks[link] &&
            socialComponentIcons[link] && (
              <li className={styles["social-link-item"]} key={link}>
                <a
                  aria-label={link}
                  key={link}
                  className={styles["social-link"]}
                  target="_blank"
                  rel="noopener  noreferrer"
                  href={socialLinks[link]}
                >
                  {socialComponentIcons[link]}
                </a>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

SocialLinks.propTypes = {
  socialLinks: PropTypes.shape({
    "facebook-url": PropTypes.string,
    "twitter-url": PropTypes.string,
    "linkedin-url": PropTypes.string,
    "youtube-url": PropTypes.string,
    "rss-url": PropTypes.string
  })
};

const AppStoreLinks = ({ appStoreLinks }) => {
  const AppStoreIcons = {
    "play-store": <Icon type="android-block" className={styles["android-css"]} />,
    "app-store": <Icon type="ios-block" className={styles["ios-css"]} />
  };
  return (
    <div className={styles["app-links-wrap"]}>
      <h5 className={styles["heading"]}>Download our app</h5>
      <ul className={styles["app-icons"]}>
        {Object.keys(appStoreLinks).map(link => {
          return (
            appStoreLinks[link] && (
              <li className={styles["app-link-item"]} key={link}>
                <a
                  aria-label={link}
                  href={appStoreLinks[link]}
                  target="_blank"
                  rel="noopener  noreferrer"
                  className={styles["app-link"]}
                >
                  {AppStoreIcons[link]}
                </a>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

AppStoreLinks.propTypes = {
  appStoreLinks: PropTypes.shape({
    "play-store": PropTypes.string,
    "app-store": PropTypes.string
  })
};

const Copyright = ({ copyright }) => {
  return (
    <div className={styles["copyright"]}>
      <div className={styles["copyright-text"]}>{copyright}. All Rights Reserved</div>
      <div className={styles["copyright-text"]}>
        Powered By{" "}
        <Link aria-label={"Open Quintype's Website"} href="https://www.quintype.com" target="_blank">
          <strong>Quintype</strong>
        </Link>
      </div>
    </div>
  );
};

Copyright.propTypes = {
  copyright: PropTypes.string
};

const Footer = ({ menuGroup = {}, socialLinks, appStoreLinks, copyright, latestNews }) => {
  const [userType, setUserType] = React.useState("");
  const [showOne, setShowOne] = React.useState(true);
  const [showTwo, setShowTwo] = React.useState(false);
  const [isBlocked, checkBlock] = React.useState(false);
  setTimeout(() => checkBlock(global.isBlock), 7000);
  useEffect(() => {
    if (global.userPromise) {
      global.userPromise.catch(() => null).then(user => {
        user && setUserType(user.userType);
      });
    }
  }, []);
  if (checkAppSlug()) {
    return false;
  }
  return (
    <footer className={styles["footer"]}>
      <ev-engagement group-name="Footer widget" />
      {userType !== "P" &&
        userType !== "T" &&
        global &&
        global !== undefined &&
        global !== null &&
        global.isBlock &&
        isBlocked === true && (
          <div>
            <DisableAdBlocker
              onClose={() => {
                if ("ga" in window) {
                  window.ga("send", "event", "closeAdblocker", "click", window.location.href);
                }
                if ("gtag" in window) {
                  window.gtag("event", "closeAdblocker", {
                    event_category: "click",
                    event_label: window.location.href
                  });
                }
                if (global.webengage && global.webengage.track) {
                  global.webengage.track("Adblock dismissed", {});
                }
                setShowOne(false);
              }}
              onSwitch={() => {
                setShowOne(false);
                setShowTwo(true);
              }}
              show={showOne}
            />
            <DisablingInsturction
              onClose={() => {
                // if ("ga" in window) {
                //   window.ga("send", "event", "closeAdblockerInstruction", "click", window.location.href);
                // }
                if ("gtag" in window) {
                  window.gtag("event", "closeAdblockerInstruction", {
                    event_category: "click",
                    event_label: window.location.href
                  });
                }
                setShowTwo(false);
              }}
              show={showTwo}
            />
          </div>
        )}
      <CookieConsent />

      <div className={styles["footer-height"]}>
        <div className={`container ${styles["vikatan-news"]}`}>
          <VikatanNews />
        </div>
      </div>
      <div className={`container row ${styles["footer-bottom-sec"]}`}>
        {menuGroup && menuGroup.items && <MenuGroup menuGroup={menuGroup} />}

        <div className={styles["social-app-copyright-wrap"]}>
          <WithHostUrl>
            {({ primaryHostUrl }) =>
              primaryHostUrl && socialLinks && !primaryHostUrl.match(/^https?:\/\/www.vikatan.tv/) ? (
                <SocialLinks socialLinks={socialLinks} />
              ) : null
            }
          </WithHostUrl>
          {appStoreLinks && <AppStoreLinks appStoreLinks={appStoreLinks} />}
          <Copyright copyright={copyright} />
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  menuGroup: PropTypes.shape(MenuGroup.propTypes),
  socialLinks: PropTypes.shape(SocialLinks.propTypes),
  appStoreLinks: PropTypes.shape(SocialLinks.propTypes),
  latestNews: PropTypes.shape(),
  copyright: PropTypes.string
};

function mapStateToProps(state) {
  return get(state, ["qt", "data", "footer"]) || {};
}

export default connect(mapStateToProps)(Footer);
