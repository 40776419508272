import React from "react";
import styles from "./styles.m.css";
import get from "lodash/get";
import { connect } from "react-redux";

const VikatanDealsHeader = () => {
  return (
    <div className={styles["main-wrapper"]}>
      <header className={styles["header"]}>
        <div className={styles["deals-container"]}>
          <a href="/vikatan-deals" className={styles["deals-header"]}>
            <img
              className={styles["logo-image"]}
              src="https://gumlet.assettype.com/vikatan/2022-09/b2915283-d514-406f-81f3-8f25bba929f0/vikatan_deals_logo.png"
            />
          </a>
        </div>
      </header>
    </div>
  );
};

function mapStateToProps(state) {
  const data = get(state, ["qt", "data", "header"]);
  const pageLoading = get(state, ["pageLoading"], false);
  const vikatanHosts = get(state, ["qt", "config", "vikatanHosts"]);
  return data ? { ...data, pageLoading, vikatanHosts } : {};
}

export default connect(mapStateToProps)(VikatanDealsHeader);
