import React from "react";
import styles from "./styles.m.css";
import get from "lodash/get";
import { connect } from "react-redux";

const VikatanDealsFooter = () => {
  return (
    <footer className={styles["footer"]}>
      © 2023 Vikatan Deals. All rights reserved |{" "}
      <a
        className={styles["footer-links"]}
        href="https://www.vikatan.com/vikatan-deals/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </a>{" "}
      |{" "}
      <a
        className={styles["footer-links"]}
        href="https://www.vikatan.com/vikatan-deals/terms-of-use-and-services"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms of Use
      </a>{" "}
      |{" "}
      <a
        className={styles["footer-links"]}
        href="https://www.vikatan.com/vikatan-deals/contact-us"
        rel="noopener noreferrer"
        target="_blank"
      >
        Contact Us
      </a>
    </footer>
  );
};

function mapStateToProps(state) {
  const data = get(state, ["qt", "data", "footer"]);
  const pageLoading = get(state, ["pageLoading"], false);
  const vikatanHosts = get(state, ["qt", "config", "vikatanHosts"]);
  return data ? { ...data, pageLoading, vikatanHosts } : {};
}

export default connect(mapStateToProps)(VikatanDealsFooter);
